//Icons

//Libraries
import { Button, Col, Form, Row, TableColumnsType } from "antd";
import { Formik } from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Meta } from "../../../models/meta.modal";
import SelectInput from "../SelectInput";

//Styles
import "./columnOptions.scss";

interface ColumnOptionsProps {
  defaultColumns: TableColumnsType<any>;
  filteredColumns: string[];
  setDefaultColumns: Dispatch<SetStateAction<TableColumnsType<any>>>;
  setFilteredColumns: Dispatch<SetStateAction<string[]>>;
  onClose: () => void;
}

const ColumnOptions = (props: ColumnOptionsProps) => {
  //Service

  //Context

  //Prop
  const {
    defaultColumns,
    onClose,
    setFilteredColumns,
    filteredColumns,
    setDefaultColumns,
  } = props;

  //State

  const [columns, setColumns] = useState<TableColumnsType>(defaultColumns);

  const [deletedColumns, setDeletedColumns] =
    useState<string[]>(filteredColumns);

  const [addColumns, setAddColumns] = useState<string[]>([]);

  const [options, setOptions] = useState<Meta[]>([]);

  useEffect(() => {
    let optionsArray: Meta[] = [];
    optionsArray = deletedColumns.map((col) => {
      return { value: col, label: col };
    });
    setOptions(optionsArray);
  }, [deletedColumns]);

  const handleRemoveColumn = (col: string) =>
    setDeletedColumns((prev) => [...prev, col]);

  const handleAddColumn = (column: string) =>
    setDeletedColumns((prev) => prev.filter((col) => col !== column));

  const handleOptionSelect = (column: string) =>
    setOptions((prev) => prev.filter((col) => col.value !== column));

  const handleAddSelect = () => setAddColumns((prev) => [...prev, ""]);

  const handleRemoveSelect = (index: number) =>
    setAddColumns((prev) => prev.filter((col, i) => i !== index));

  const handleSubmit = () => {
    setFilteredColumns(deletedColumns);
    setDefaultColumns(columns);
    onClose();
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    const items = Array.from(columns);
    const [newOrder] = items.splice(source.index, 1);
    items.splice(destination.index, 0, newOrder);
    setColumns(items);
  };

  return (
    <div className="column-options">
      <div className="column-options__desc">
        Add or remove columns. To change the column order, drag and drop a
        field.
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="defaultColumns">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {columns
                .filter((col) => !deletedColumns.includes(col.title as string))
                .map((col, index) => {
                  return (
                    col.title && (
                      <Draggable
                        key={col.title as string}
                        draggableId={col.title as string}
                        index={index}
                      >
                        {(provided) => (
                          <Row
                            className="column-options__item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Col
                              span={22}
                              className="column-options__item__label"
                            >
                              {col.title}
                            </Col>
                            <div
                              className="column-options__item__delete"
                              onClick={() =>
                                handleRemoveColumn(col.title as string)
                              }
                            >
                              x
                            </div>
                          </Row>
                        )}
                      </Draggable>
                    )
                  );
                })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Formik initialValues={{}} onSubmit={() => {}} enableReinitialize>
        {({}) => (
          <div className="user-form__container">
            <Form className="ant-form-vertical">
              <Row gutter={24}>
                {addColumns.map((_, i) => (
                  <Col span={22} key={`${options[i].value}_${i}`}>
                    <SelectInput
                      name={`${options[i].value}_${i}`}
                      options={options}
                      placeholder="Select"
                      defaultValue={undefined}
                      onSelect={(e) => {
                        handleRemoveSelect(i);
                        handleAddColumn(e);
                        handleOptionSelect(e);
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </Form>
          </div>
        )}
      </Formik>
      {deletedColumns.length > addColumns.length && (
        <div className="column-options__add" onClick={handleAddSelect}>
          + Add Column
        </div>
      )}

      <div className="column-options__footer drawer-footer">
        <Button
          className="drawer-footer__cancel"
          htmlType="reset"
          onClick={onClose}
          type="link"
        >
          Cancel
        </Button>
        <Button
          className="drawer-footer__submit"
          htmlType="submit"
          onClick={handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ColumnOptions;
