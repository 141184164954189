import { Center, CenterParams } from "../../models/center.model";
import { deserialize, serialize } from "serializr";

import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationMeta } from "../../models/pagination.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { useState } from "react";
import { FileAttachment } from "../../models/fileAttachment.model";
import { generatePath } from "react-router-dom";

export const FileService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const uploadFile = async (attachment: File) => {
    setLoading(true);

    const endpoint = generatePath(ApiRoutes.UPLOAD_FILE);
    const formData = new FormData();
    formData.append("attachment", attachment);
    try {
      const { data } = await axiosInstance.post(endpoint, formData);
      return data;
    } catch (ex) {
      Notification({
        message: "Unable to Upload Document",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  return { uploadFile, loading };
};
