import "./branchSummary.scss";

import { Badge, Col, Drawer, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Branch } from "../../../../models/branch.model";

import BranchDetailForm from "../../Branches/BranchDetailForm";
import { BranchesService } from "../../../../services/Branches/branches.service";
import { Center } from "../../../../models/center.model";
import { CentersService } from "../../../../services/Centers/centers.service";
import { useParams } from "react-router-dom";
import { fillEmptyData } from "../../../../shared/utils/fillEmptyData";
import { ToggleDrawer } from "../../../../shared/types/toggleDrawer";
import BranchStepWrapper from "../../Branches/BranchStepWrapper";
import Maps from "../../../../shared/components/Maps";

interface BranchSummaryProps {
  editBranch: boolean;
  toggleEditBranch: ToggleDrawer;
}

const BranchSummary = (props: BranchSummaryProps) => {
  const { loading, getBranch, updateBranch } = BranchesService();

  const { getCenters, loading: centerLoading } = CentersService();

  const { id: branchId } = useParams();

  const { editBranch, toggleEditBranch } = props;

  const [branch, setBranch] = useState<Branch>();

  const [centers, setCenters] = useState<Center[]>();

  const handleGetBranch = async () => {
    const fetchedBranch = await getBranch(String(branchId));
    setBranch(fetchedBranch);
  };

  const handleGetCenters = async () => {
    const fetchedCenters = await getCenters({ branchId: [Number(branchId)] });

    setCenters(fetchedCenters?.centers);
  };

  const handleEditSubmit = async (branch: Branch) => {
    const updatedBranch = await updateBranch(branch);
    console.log({ updatedBranch });
    if (updatedBranch) {
      setBranch(updatedBranch);
      handleGetCenters();
      handleGetBranch();
      toggleEditBranch();
    }
  };

  useEffect(() => {
    handleGetCenters();
    handleGetBranch();
  }, []);

  return (
    <>
      <div className="card">
        <div className="branch-summary">
          {loading || centerLoading ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : (
            <Row>
              <Col md={12}>
                <Row gutter={[0, 24]}>
                  <Col className="branch-summary__label" md={10}>
                    branch name
                  </Col>
                  <Col className="branch-summary__value" md={14}>
                    {fillEmptyData(branch?.name)}
                  </Col>
                  <Col className="branch-summary__label" md={10}>
                    branch id
                  </Col>
                  <Col className="branch-summary__value" md={14}>
                    {fillEmptyData(branch?.code)}
                  </Col>
                  <Col className="branch-summary__label" md={10}>
                    email
                  </Col>
                  <Col className="branch-summary__value" md={14}>
                    {fillEmptyData(branch?.email)}
                  </Col>
                  <Col className="branch-summary__label" md={10}>
                    phone
                  </Col>
                  <Col className="branch-summary__value" md={14}>
                    {fillEmptyData(branch?.mobileNumber)}
                  </Col>
                  <Col className="branch-summary__label" md={10}>
                    preferred channel
                  </Col>
                  <Col className="branch-summary__value" md={14}>
                    {fillEmptyData(branch?.organizationName)}
                  </Col>
                  <Col className="branch-summary__label" md={10}>
                    address
                  </Col>
                  <Col className="branch-summary__value" md={14}>
                    {fillEmptyData(branch?.address?.getAddress())}
                  </Col>
                  <Col className="branch-summary__label" md={10}>
                    status
                  </Col>
                  <Col className="branch-summary__value" md={14}>
                    {branch?.isActive ? (
                      <Badge className="badge-active" count="Active" />
                    ) : (
                      <Badge className="badge-inactive" count="Inactive" />
                    )}
                  </Col>
                  <Col className="branch-summary__label" md={10}>
                    Max Dues Collection per day
                  </Col>
                  <Col className="branch-summary__value" md={14}>
                    {branch?.maxDueCollectionPerDay ?? "-"}
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                {" "}
                {branch?.address?.latitude && (
                  <Maps
                    disabled={true}
                    height="60vh"
                    width="40vw"
                    latName="address.latitude"
                    lngName="address.longitude"
                    centerValue={{
                      lat: branch?.address?.latitude ?? 0,
                      lng: branch?.address?.longitude ?? 0,
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
        </div>
      </div>
      <Drawer
        placement="right"
        onClose={() => toggleEditBranch(false)}
        visible={editBranch}
        width="916"
        title="Edit Branch"
        destroyOnClose
      >
        <BranchStepWrapper
          formValues={branch}
          onSubmit={handleEditSubmit}
          onClose={() => {
            toggleEditBranch(false);
          }}
        />
      </Drawer>
    </>
  );
};

export default BranchSummary;
