import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import crossIcon from "../../../Assets/images/cross.png";
import { Filters } from "../../../models/filters.model";
import "./filterButtons.scss";
import { replaceFilterKeys } from "../../utils/replaceFilterKeys";
import { removeUnderscore } from "../../utils/removeUnderscore";

interface FilterButtonsProps {
  filters: Filters;
  loading?: boolean;
  onFilter: () => void;
}

const FilterButtons = (props: FilterButtonsProps) => {
  const { filters, onFilter } = props;

  const location = useLocation();
  const url = `${location.pathname}${location.search}`;
  const updatedFiters = replaceFilterKeys(filters, location);

  const navigate = useNavigate();
  const handleClearAllFilters = () => {
    navigate(".", { replace: true });
    onFilter();
  };

  const clearAllButton = Object.values(filters).flat().length ? (
    <Button className="btn-filter" onClick={handleClearAllFilters}>
      <span className="text-danger"> Clear All</span>
    </Button>
  ) : null;

  function handleRemoveFilter(key: string, value: string) {
    const paramsArr = url.split("?")[1].split("&");
    for (let i = 0; i < paramsArr.length; i++) {
      const paramKey = paramsArr[i].split("=")[0];
      const paramValue = paramsArr[i].split("=")[1];
      if (paramKey === key && paramValue === value) {
        paramsArr.splice(i, 1);
        i--; 
      }
    }

    let pageParamIndex = paramsArr.findIndex(param => param.startsWith('page='));
    if (pageParamIndex !== -1) {
      paramsArr[pageParamIndex] = 'page=1';
    } else {
      paramsArr.push('page=1');
    }
    
    const updatedUrl = url.split("?")[0] + "?" + paramsArr.join("&");
    const queryParams = new URLSearchParams(updatedUrl.split("?")[1]).toString();
    navigate(`?${queryParams}`, { replace: true });
    onFilter();
  }
  const renderButton = (key: string, label: string, value: string) => {
    return (
      <Button
        key={key}
        className="btn-filter"
        onClick={() => handleRemoveFilter(key, value)}
      >
        <div className="d-flex">
          <span>{removeUnderscore(label)}</span>
          <span className="remove-filter">
            <img height={17} width={17} src={crossIcon} />
          </span>
        </div>
      </Button>
    );
  };

  const filterButtons = useMemo(() => {
    const buttons = Object.entries(updatedFiters ?? {}).map(
      ([key, values], index) => (
        <div key={key}>
          {Array.isArray(values) && typeof values[0] === "string"
            ? values.length > 0 &&
              values.map((value: string, i: number) =>
                renderButton(key, value, value)
              )
            : values?.length
            ? values.map(
                ({ label, value }: { label: string; value: number | string }) =>
                  renderButton(key, label, String(value))
              )
            : null}
        </div>
      )
    );

    return [clearAllButton, ...buttons];
  }, [filters]);

  return filters && <div className="filter-buttons ">{filterButtons}</div>;
};

export default FilterButtons;
