import React from "react";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../Assets/images/back-arrow.png";

import "./back.scss";

interface BackProps {}

const Back = (props: BackProps) => {
  const {} = props;

  // Context
  const navigate = useNavigate();

  return (
    <div className="back">
      <img width="7" height="10" src={BackArrow} alt="Not Found" />
      <span onClick={() => navigate(-1)}>Back</span>
    </div>
  );
};

export default Back;
