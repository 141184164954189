import { LoanStatus } from "../../enums/loanStatus.type";

export const getLoanStatusLabel = (status?: LoanStatus) => {
  switch (status) {
    case LoanStatus.SUBMITTED:
      return "Submitted";

    case LoanStatus.CT_VERIFICATION:
      return "CT Verification";

    case LoanStatus.APPROVED:
      return "Approved";

    case LoanStatus.REJECTED:
      return "Rejected";

    case LoanStatus.DISBURSED:
      return "Disbursed";

    case LoanStatus.DRAFT:
      return "Draft";

    case LoanStatus.RE_SUBMIT:
      return "Re Submit";

    case LoanStatus.RE_APPLY:
      return "Re Apply";

    case LoanStatus.E_SIGN:
      return "E-Sign";

    case LoanStatus.APPLIED:
      return "Applied";

    case LoanStatus.LINKED:
      return "Pending"

    default:
      return status;
  }
};
