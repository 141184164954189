import "./individual.scss";

import * as AppRoutes from "../../../routes/routeConstants/appRoutes";

import { Customer, CustomerParams } from "../../../models/customer.model";
import React, { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import useSorting from "../../../shared/hooks/useSorting";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { TableConfig } from "../../../enums/tableConfig.type";

import CustomerFilters from "./CustomersFilters";
import { CustomersService } from "../../../services/Customers/customers.service";
import { Drawer, TableColumnsType } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import Table from "../../../shared/components/Table";
import { TableProps } from "antd/lib/table";
import { ToggleDrawer } from "../../../shared/types/toggleDrawer";
import { columns } from "./individualColumn";
import { useEffect } from "react";
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import { DateRange } from "../../../shared/types/date.type";
import { CustomerType } from "../../../enums/customerType";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import useDrawer from "../../../shared/hooks/useDrawer";

const sortBy = {
  code: "code",
  email: "email",
  firstName: "firstname",
  phoneNumber: "phone_number",
  isActive: "is_active",
  center: "center.branch.name",
  createdBy: "created_by.firstname",
  aadhaarNumber: 'aadhaar_number',
  croName: 'created_by.firstname'
};

interface IndividualProps {
  params: CustomerParams;
  filterVisible?: boolean;
  onFilter?: ToggleDrawer;
  settingsVisible?: boolean;
  onSettings?: ToggleDrawer;
  dateRange?: DateRange;
  customerType?: string;
}

const Individual = (props: IndividualProps) => {
  const { getCustomers, getCreatedCustomers, loading } = CustomersService();
  const {
    params,
    handleSearch: handleCustomerSearch,
    updateParams,
    getParams,
  } = useQueryParams({
    params: new CustomerParams(),
  });

  const { customerType } = props

  const {
    visible: customerFilterVisible,
    toggleVisibility: toggleCustomerFilterVisibility,
  } = useDrawer({});

  const {
    visible: customerSettingsVisible,
    toggleVisibility: toggleCustomerSettingsVisibility,
  } = useDrawer({});

  const { userId } = useParams<{ userId: string }>();

  const { updateSortData } = useSorting<Customer>({ sortBy });

  const navigate = useNavigate();

  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Customer>>(columns);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>();
  const [totalCustomers, setTotalCustomers] = useState(1);
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.customers ?? []
  );
  const [filters, setFilters] = useState<Filters>(new Filters());

  const populateCustomer = async (params: CustomerParams) => {
    const customersData =
      customerType === CustomerType.CREATED
        ? await getCreatedCustomers(String(userId), params)
        : await getCustomers(params);
    if (customersData?.customers) setCustomers(customersData?.customers);
    if (customersData?.meta) {
      setTotalCustomers(customersData?.meta?.totalCount ?? 1);
      customersData?.meta?.filters && setFilters(customersData?.meta?.filters);
    }
  };

  const handleChange: TableProps<Customer>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<Customer>);
    updateParams({
      ...params,
      ...sortData,
      page: pagination?.current,
      ...dateRange,
    });
  };

  useEffect(() => {
    const updatedParams = params?.searchText
      ? { ...params, searchText: params?.searchText }
      : params;
    populateCustomer({ ...updatedParams, ...dateRange });
  }, [params, params?.searchText, dateRange]);

  useEffect(() => {
    setColumnConfig(TableConfig.CUSTOMERS, filteredColumns);
  }, [filteredColumns]);
  const handleUpdateParams = () => updateParams(getParams());

  const handleDateChange = (date: DateRange) => {
    setDateRange(date);
    updateParams({ ...params, ...date });
  };

  return (
    <div className="customers-section">
      {!loading && customerType !== CustomerType.CREATED && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      {customerType !== CustomerType.CREATED && (
       <div className="d-flex customers-extra-content">
      <CustomRangePicker
        className="mr-5 custom-range-picker"
        onChange={handleDateChange}
        values={{
          toDate: params?.toDate,
          fromDate: params?.fromDate,
        }}
      />
      <TabExtraContent
        searchProps={{
          onSearch: handleCustomerSearch,
          placeholder: "Search Customer",
          value: params?.searchText
        }}
        filterClick={toggleCustomerFilterVisibility}
        settingsClick={toggleCustomerSettingsVisibility}
      />
    </div>
    )}
      <Table
        className="custom-scrollbar"
        scroll={{ x: true }}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={customers}
        loading={loading}
        onChange={handleChange}
        rowKey={(record) => record?.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(
              generatePath(AppRoutes.CUSTOMERS_DETAIL, {
                customerId: record?.id,
              })
            );
          },
        })}
        pagination={{
          current: params?.page,
          hideOnSinglePage: true,
          total: totalCustomers,
        }}
      />
      {customerFilterVisible && toggleCustomerFilterVisibility && (
        <Drawer
          placement="right"
          onClose={() => toggleCustomerFilterVisibility(false)}
          visible={customerFilterVisible}
          width={"70vw"}
          title="Filters"
          closable
          destroyOnClose
        >
          <CustomerFilters
            onClose={() => toggleCustomerFilterVisibility(false)}
            onFilter={updateParams}
          />
        </Drawer>
      )}

      {customerSettingsVisible&& toggleCustomerSettingsVisibility && (
        <Drawer
          placement="right"
          onClose={() => toggleCustomerSettingsVisibility(false)}
          visible={customerSettingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => toggleCustomerSettingsVisibility(false)}
            setDefaultColumns={setDefaultColumns}
          />
        </Drawer>
      )}
    </div>
  );
};

export default Individual;
