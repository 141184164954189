import { useState } from "react"
import { ClaimDocumentUpload, LoanClaimsModel, LoanClaimsParams, ProcessGroupClaim, ProcessIndividualClaim, ProcessMarkedClaim } from "../../models/loanClaims.model"
import { deserialize, serialize } from "serializr"
import axiosInstance from "../../interceptor/axiosInstance"
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes"
import { PaginationMeta } from "../../models/pagination.model"
import Notification from "../../shared/components/Notification"
import { NotificationTypes } from "../../enums/notificationTypes"
import { generatePath } from "react-router-dom"

export const ClaimsService = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()

    const getClaims = async (params?: LoanClaimsParams) => {
        setLoading(true)
        const queryParams = params ? serialize(LoanClaimsParams, params) : {}
        try {
            const { data } = await axiosInstance.get(ApiRoutes.LISTING_CLAIMS, {
                params: queryParams
            })
            const deserializedClaims = deserialize(LoanClaimsModel, data["claims"] as LoanClaimsModel[])
            const deserializedClaimsMeta = deserialize(PaginationMeta, data?.["meta"]);
            return { claims: deserializedClaims, meta: deserializedClaimsMeta };
        } catch (ex) {
            Notification({
                message: "Unable to Get Claims",
                description: "Unable to Get Claims Details",
                type: NotificationTypes.ERROR,
            });
            setError(ex as Error);
        } finally {
            setLoading(false);
        }
    }

    const processGroupClaims = async (claims: ProcessGroupClaim) => {
        setLoading(true);
        try {
            const serializedClaim = serialize(ProcessGroupClaim, claims);
            const serializedClaimIds = serializedClaim.claim_ids;
            const endpoint = `${ApiRoutes.PROCESS_GROUP_CLAIM}?${serializedClaimIds?.map((id: string) => `claim_ids[]=${id}`).join('&')}`;
            const { data } = await axiosInstance.put(endpoint,
                {
                    claim: serializedClaim,
                })
            Notification({
                message: "Claim Processed Successfully",
                description: "",
                type: NotificationTypes.SUCCESS,
            });
            return true;
        } catch (ex) {
            Notification({
                message: "Claim Processing Failed",
                description: "",
                type: NotificationTypes.ERROR,
            });
        } finally {
            setLoading(false);
        }
    }

    const processClaim = async (claimData: ProcessIndividualClaim) => {
        setLoading(true);
        try {
            const serializedClaim = serialize(ProcessIndividualClaim, claimData)
            const endpoint = generatePath(ApiRoutes.PROCESS_INDIVIDUAL_CLAIM, {
                claimId: claimData?.claimId
            })
            const { data } = await axiosInstance.put(endpoint, {
                claim: serializedClaim
            })
            Notification({
                message: "Claim Initiated Successfully",
                description: "",
                type: NotificationTypes.SUCCESS,
            });
            return true;
        } catch (ex) {
            Notification({
                message: "Claim Initiation Failed",
                description: "",
                type: NotificationTypes.ERROR,
            });
        } finally {
            setLoading(false);
        }
    }

    const getIndividualClaimSummary = async (loanId: number) => {
        setLoading(true);
        try {
            const endpoint = generatePath(ApiRoutes.GET_INDIVIDUAL_CLAIM)
            const { data } = await axiosInstance.get(endpoint, {
                params: {
                    loan_id: loanId,
                }
            })
            const deserializedClaim = deserialize(LoanClaimsModel, data["claim"]);
            return deserializedClaim
        } catch (ex) {
            Notification({
                message: "Claim Summary Fetching Failed",
                description: "",
                type: NotificationTypes.ERROR,
            });
        } finally {
            setLoading(false);
        }
    }

    const processMarkedClaim = async (claimData: ProcessMarkedClaim) => {
        setLoading(true);
        try {
            const endpoint = generatePath(ApiRoutes.PROCESS_MARKED_CLAIM, { claimId: claimData?.claimId?.toString() });
            const serializedClaim = serialize(ProcessMarkedClaim, claimData)
            const { data } = await axiosInstance.put(endpoint,
                {
                    claim: serializedClaim
                })
            Notification({
                message: "Claim Process started Successfully",
                description: "",
                type: NotificationTypes.SUCCESS,
            });
            return true;
        } catch (ex) {
            Notification({
                message: "Claim Processing Failed",
                description: "",
                type: NotificationTypes.ERROR,
            });
        } finally {
            setLoading(false);
        }
    }

    const claimDocumentUpload = async (documentData: ClaimDocumentUpload) => {
        setLoading(true);
        try {
            const endpoint = generatePath(ApiRoutes.CLAIM_DOCUMENT_UPLOAD, { claimId: documentData?.claimId?.toString() });
            const serializedClaim = serialize(ClaimDocumentUpload, documentData)
            if (serializedClaim?.document_name) {
            const { data } = await axiosInstance.put(endpoint,
                {
                    claim: {
                        other_document: serializedClaim
                    }
                })
            } else if (serializedClaim?.death_certificate_id) {
                const { data } = await axiosInstance.put(endpoint, {
                    claim: serializedClaim
                })
            }
            Notification({
                message: "Claim Document Uploaded Successfully",
                description: "",
                type: NotificationTypes.SUCCESS,
            });
            return true;
        } catch (ex) {
            Notification({
                message: "Claim Document Upload Failed",
                description: "",
                type: NotificationTypes.ERROR,
            });
        } finally {
            setLoading(false);
        }

    }
    return { getClaims, processClaim, processGroupClaims, getIndividualClaimSummary, processMarkedClaim, claimDocumentUpload, loading, error }
}