import { Col, Collapse, Row, Space } from "antd";
import React from "react";
import monthlyEMI from "../../../../../Assets/images/Monthly EMI.svg";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { MonthlyEmi } from "../../../../../models/monthlyEmi.model";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { AccountsDetails } from "../../../../../models/accountsDetails.model";
import PaymentHistory from "../../../Total Accounts/PaymentTable";

interface MonthlyEMIProps {
  data?: MonthlyEmi;
}

const MonthlyEMI = (props: MonthlyEMIProps) => {
  const { data } = props;

  const { Panel } = Collapse

  const renderPanelContent = (emiDetail: AccountsDetails) => {
    const overDuePanelContent: ListMappingKeys[] = [
      {
        label: "Account Number",
        value: emiDetail.accountNumber,
        offset: 4,
        key: "1",
      },
      {
        label: "Weekly",
        value: emiDetail.weekly,
        offset: 4,
        className: "text__label-green font-size__18 font-weight__600",
        key: "2",
      },
      {
        label: "Last Updated",
        value: emiDetail.reportedOn,
        offset: 4,
        key: "3",
      },
      {
        label: "Status",
        value: emiDetail.accountStatus,
        offset: 4,
        key: "4",
      },
    ];

    return (
      <Row className={`card crif-card mt-5`} align={"middle"}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={monthlyEMI} alt="" />
          </Col>
          <Col className="flex-column">
            <span className="text__value">{emiDetail.lender}</span>
            <span className="text__label">{emiDetail.accountType}</span>
          </Col>
        </Space>
        {overDuePanelContent.map((content) => (
          <Col offset={content.offset} key={content.key} className="flex-column">
            <span className={`text__value ${content.className}`}>{content.value}</span>
            <span className="text__label">{content.label}</span>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div>
      <Row className="card crif-card" align={"middle"}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={monthlyEMI} alt="" />
          </Col>
          <Col>Monthly EMI</Col>
        </Space>
        <Col offset={6} className="flex-column">
          <span className="font-weight__600 font-size__18">{data?.monthlyEmis || 0}</span>
          <span>Monthly EMI Loans</span>
        </Col>
        <Col offset={9} className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">{currencyFormatter({ amount: data?.totalMonthlyEmiAmount, hasCurrencySign: true, shouldReturnNA: false })}</span>
            <span>/month</span>
          </div>
          <span className={`text__green text-right`}>{fillEmptyData(data?.status)}</span>
        </Col>
      </Row>
      <div className="payment-status">
        <Collapse expandIconPosition="end" bordered={false} ghost>
          {data?.monthlyEmiAccounts?.map((emiDetail, index) => (
            <Panel header={renderPanelContent(emiDetail)} key={index + 1}>
              <PaymentHistory account={emiDetail} />
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default MonthlyEMI;
