import React, { useEffect, useState } from "react";
import { TabsProps } from "../../../shared/types/Tabs.type";
import AccountantLoans from "./../AccountantLoans";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { AccountantParams } from "../../../models/accountant.model";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import useDrawer from "../../../shared/hooks/useDrawer";
import { useLocation } from "react-router-dom";
import { Button, Tabs } from "antd";
import '../accountant.scss'
import PendingRequests from "./PendingRequests";
import RejectedRequests from "./RejectedRequests";

const AccountantHome = () => {
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation();

  const {
    params,
    handleSearch,
    updateParams,
    getParams,
  } = useQueryParams<AccountantParams>({
    params: new AccountantParams(),
  });

  useEffect(()=> {
    if (!location.hash) {
      window.location.hash = tabs?.find((tab) => tab.key === activeTab)?.hash ?? '';
    }
  },[location.hash])

  useEffect(() => {
    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );
  }, []);

  const tabs: TabsProps[] = [
    {
      label: "Pending",
      key: "1",
      component: (
        <PendingRequests/>
      ),
      hash: "#pending",
    },
    {
      label: "Completed",
      key: "2",
      component: (
        <RejectedRequests/>
      ),
      hash: "#completed",
    },
  ];

  const handleTabChange = (activeKey: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === activeKey)?.hash;
    setActiveTab(activeKey);
    updateParams({ page: 1 }, hash)
  };
  const tabDetails = tabs?.find((tab) => location?.hash === tab?.hash)
  const tabKey = String(tabDetails?.key)

  return (
    <div className="accountant">
      <p className="header">Collection Regularisation</p>
      <Tabs
        activeKey={tabKey !== undefined ? tabKey : "1"}
        defaultActiveKey="1"
        onChange={handleTabChange}
      >
        {tabs.map(({ component, ...tab }) => (
          <Tabs.TabPane tab={tab.label} key={tab.key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default AccountantHome;
