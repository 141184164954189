import React, { useEffect, useState } from "react";
import { Col, Row, Select, Spin } from "antd";
import DashStats from "../DashStats";
import StatsGraph from "../../../shared/components/StatsGraph";
import { AuthContext } from "../../../context/AuthContext";
import { DashboardService } from "../../../services/Dashboard/dashboardService.service";
import { Months } from "../../../enums/months.type";
import "../dashboard.scss";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import { Graph } from "../../../enums/Graph.type";
import { getNCharacters } from "../../../shared/utils/getNCharacters";
import { DateRange } from "../../../shared/types/date.type";
import useBranch from "../../../shared/hooks/useBranch";
import { Meta } from "../../../models/meta.modal";

import { MetaService } from "../../../services/Meta/meta.service";

import { CardProps } from "../../../shared/types/card.type";
import { User } from "../../../enums/user.type";
import {
  MISDashboard as MISDashboardModel,
  MISDashboardParams,
} from "../../../models/misDashboards.model";
import { GraphType } from "../../../enums/graphType";

interface MISDashboardProps {
  selectedRole?: string;
  onRoleChange: (role: User) => void;
}

interface GraphFilters {
  branch?: string;
  loanType?: string;
  loanApplication?: DateRange;
  loanApproval?: DateRange;
}

const MISDashboard = (props: MISDashboardProps) => {
  const { selectedRole, onRoleChange } = props;
  const { user } = AuthContext();
  //permission yet not granted
  const { branches, loading: branchLoading } = useBranch({ allBranch: true });
  const { getDashboard, loading, getHeadofficerGraph } = DashboardService();
  const { getLoanTypes, loading: metaLoading } = MetaService();
  const [loanTypesOptions, setLoanTypeOptions] = useState<Meta[]>([]);
  const [dashboardGraph, setDashboardGraph] = useState<MISDashboardModel>();
  const [dashboardData, setDashboardData] = useState<MISDashboardModel>();
  const [filter, setFilter] = useState<GraphFilters>();

  useEffect(() => {
    handleGetDashboard({ user: selectedRole });
    fetchLoanTypes();

    //this line will be repaced by MIS graph once implemented
    handleDashboardGraph(Graph.APPLICATION);
    handleDashboardGraph(Graph.APPROVAL);
  }, []);
  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    data && setLoanTypeOptions(data);
  };

  const handleGetDashboard = async (params?: MISDashboardParams) => {
    const data = await getDashboard(params);
    data && setDashboardData(data);
  };

  const handleFilter = (value: MISDashboardParams) =>
    onRoleChange(value.user as User);

  const handleDateChange = (value: DateRange) => handleGetDashboard(value);

  const handleGraphData = (
    graphData: GraphType.LOAN_APPLICATION | GraphType.LOAN_APPROVALS
  ): { key: string; count: number; value: number }[] => {
    return dashboardGraph
      ? Object.values(Months).map((key) => ({
          key,
          count:
            (dashboardGraph[graphData] || []).find(
              (item) => getNCharacters(String(item?.month), 3) === key
            )?.sum ?? 0,
          value:
            (dashboardGraph[graphData] || []).find(
              (item) => getNCharacters(String(item?.month), 3) === key
            )?.count ?? 0,
        }))
      : [];
  };

  const card: CardProps[] = [
    {
      label: "Total Outstanding",
      count: dashboardData?.totalOutstanding,
    },
    {
      label: "Total Disbursement",
      count: dashboardData?.totalDisbursement,
    },
    {
      label: "Total own Portifolio",
      count: dashboardData?.totalOwnPortfolio,
    },
    {
      label: "Total managed Portifolio",
      count: dashboardData?.totalManagedPortfolio,
    },

    {
      label: "Total Loans",
      count: dashboardData?.totalLoans,
    },
    {
      label: "Total Borrowers",
      count: dashboardData?.totalBorrowers,
    },
    {
      label: "Total Active Loans",
      count: dashboardData?.totalActiveLoans,
    },
    {
      label: "Total Active Borrowers",
      count: dashboardData?.totalActiveBorrowers,
    },
  ];
  const handleDashboardGraph = async (
    type: Graph,
    values?: DateRange,
    filter?: MISDashboardParams
  ) => {
    //this headOfficer Graph will be repaced by MIS Dashboard
    const data = await getHeadofficerGraph({ ...values, ...filter }, type);

    data &&
      setDashboardGraph((existingData) => ({
        ...existingData,
        [type === Graph.APPLICATION
          ? GraphType.LOAN_APPLICATION
          : GraphType.LOAN_APPROVALS]: data,
      }));
  };

  return (
    <div className="mis-manager dashboard">
      {loading && (
        <div className="spin-overlay">
          <Spin />
        </div>
      )}
      <Row gutter={[30, 16]}>
        <Col span={10}>
          <DashStats
            employee={user?.employee}
            card={card}
            onFilter={handleFilter}
            role={selectedRole}
            onDateChange={handleDateChange}
          />
        </Col>
        <Col span={14}>
          {dashboardGraph?.loanApplications && (
            <StatsGraph
              title="Loan Applications"
              hasCard
              data={handleGraphData(GraphType.LOAN_APPLICATION)}
              axes={{
                x: "Total Loans",
                y: "Total Amount",
              }}
              barColor="#22efb0"
              extraContent={
                <Row gutter={[20, 20]}>
                  <Col span={14}>
                    <CustomRangePicker
                      onChange={(values) => {
                        {
                          handleDashboardGraph(Graph.APPLICATION, values, {
                            loanType: filter?.loanType,
                          });
                          setFilter({ ...filter, loanApplication: values });
                        }
                      }}
                    />
                  </Col>
                  <Col span={10}>
                    <Select
                      options={loanTypesOptions}
                      placeholder="Select Loan"
                      onChange={(loanType) => {
                        handleDashboardGraph(
                          Graph.APPLICATION,
                          filter?.loanApplication,
                          { loanType }
                        );
                        setFilter({ ...filter, loanType });
                      }}
                    />
                  </Col>
                </Row>
              }
            />
          )}

          {dashboardGraph?.loanApprovals && (
            <StatsGraph
              title="Loan Approvals"
              hasCard
              data={handleGraphData(GraphType.LOAN_APPROVALS)}
              axes={{
                x: "Aproved Loans",
                y: "Total Amount",
              }}
              barColor="#2276ef"
              extraContent={
                <Row gutter={[20, 20]}>
                  <Col span={14}>
                    <CustomRangePicker
                      onChange={(loanApproval) => {
                        handleDashboardGraph(Graph.APPROVAL, loanApproval, {
                          ...filter,
                          branch: filter?.branch,
                        });
                        setFilter({ ...filter, loanApproval });
                      }}
                    />
                  </Col>
                  <Col span={10}>
                    <Select
                      className="branch-select"
                      allowClear
                      options={branches}
                      loading={branchLoading}
                      placeholder="Select Branch"
                      onChange={(branch) => {
                        handleDashboardGraph(
                          Graph.APPROVAL,
                          filter?.loanApproval,
                          { branch }
                        );
                      }}
                    />
                  </Col>
                </Row>
              }
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MISDashboard;
