import { Col, Row } from "antd";
import React, { ReactElement } from "react";
import { BusinessDetail } from "../../../../../models/businessDetail.model";

import { ExistingLoan } from "../../../../../models/existingLoan.model";
import { FamilyDetail } from "../../../../../models/FamilyDetail.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { removeUnderscore } from "../../../../../shared/utils/removeUnderscore";
import { Loan } from "../../../../../models/loan.model";
import { isNotEmptyArray } from "../../../../../shared/utils/isNotEmptyArray";

interface ExistingLoanDetailsProps {
  loan?: Loan
  loans: ExistingLoan[];
  familyDetail?: FamilyDetail;
}

const ExistingLoanDetails = (props: ExistingLoanDetailsProps) => {
  const { loans, familyDetail, loan } = props;

  const data = [
    {
      label: "Loan Type",
      value: (data: ExistingLoan) =>
        fillEmptyData(removeUnderscore(data?.loanType ?? "")),
    },
    {
      label: "Name of the Institution",
      value: (data: ExistingLoan) => fillEmptyData(data?.institutionName),
    },
    {
      label: "Loan Amount",
      value: (data: ExistingLoan) =>
        currencyFormatter({
          amount: data?.amount,
          hasCurrencySign: true,
        }),
    },
    {
      label: "monthly EMI",
      value: (data: ExistingLoan) =>
        currencyFormatter({
          amount: Number(data?.monthlyEmi),
          hasCurrencySign: true,
        }),
    },
  ];

  return (
    <div>
      <Row gutter={[50, 0]}>
        {isNotEmptyArray(loans) && (
          <Col span={12} className="block__heading mb-5">
            Existing Loan Details
          </Col>
        )}
        <Col span={12} className="block__heading mb-5">
          Income Summary
        </Col>
      </Row>

      <Row className="block" gutter={[50, 0]}>
        {isNotEmptyArray(loans) && (
          <Col span={12}>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  {data.map((field) => (
                    <th key={field.label}>{field.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loans?.map((person, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {data.map((field) => (
                      <td className="text-capitalize" key={field.label}>
                        {person ? field.value(person) : "NA"}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr className="family-informations__last-child">
                  <td colSpan={11}>
                    <h3 className="text-heading text-bold text-right">
                      Total Monthly EMI :
                      <span className="ml-5">
                        {currencyFormatter({
                          amount: familyDetail?.totalMonthlyEmi ?? 0,
                          hasCurrencySign: true,
                        })}
                      </span>
                    </h3>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        )}
        <Col span={12}>
          <Row gutter={[0, 15]}>
            <Col span={12} className="block__label">
              Total Monthly Family Income{" "}
              <div className="block__value">
                {currencyFormatter({
                  amount: familyDetail?.totalMonthlyIncome ?? 0,
                  hasCurrencySign: true,
                })}
              </div>
            </Col>
            <Col span={12} className="block__label">
              Monthly Other EMI
              <div className="block__value">
                {`₹${familyDetail?.totalMonthlyEmi ?? 0}`}
              </div>
            </Col>
            <Col span={12} className="block__label ">
            Monthly Income Balance
              <div className="block__value ">
                <span className="text-link">
                  {currencyFormatter({
                    amount: familyDetail?.totalMonthlyBalance ?? 0,
                    hasCurrencySign: true,
                  })}
                </span>
              </div>
            </Col>
            <Col span={12} className="block__label ">
            CO Income Description
              <div className="block__value ">
                <span>
                  {fillEmptyData(loan?.description)}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ExistingLoanDetails;
