import React from "react";
import { Button, Col, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import "../auth.scss";
import InputField from "../../../shared/components/InputField";
import AuthService from "../../../services/AuthService/auth.service";

import { User } from "../../../models/user.model";
import { InputType } from "../../../enums/input.type";
import mainLogo from "../../../Assets/images/main-logo.png";

import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { forgotPasswordValidation } from "./forgotPasswordValidation";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";

function ForgotPassword() {
  const { loading, login, forgotPassword } = AuthService();
  const navigate = useNavigate();

  const onSubmit = async (values: User) => {
    const result = await forgotPassword(values);
    if (result) {
      LocalStorageHelper.setItem(LocalStorage.EMPLOYEE, values);
      navigate(AppRoutes.VERIFY_OTP);
    }
  };
  return (
    <Row className="forgot-password">
      <Col span={12} className="login__logo-container">
        <img src={mainLogo} alt="logo" />
      </Col>
      <Col span={12} className="content-container">
        <div className="forgot-password__form">
          <Formik
            validationSchema={forgotPasswordValidation}
            initialValues={new User()}
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form className="ant-form-vertical" onSubmit={props.handleSubmit}>
                <h1 className="forgot-password__form__heading">
                  Forgot Password ?
                </h1>
                <InputField
                  label="Email ID"
                  type={InputType.EMAIL}
                  name="email"
                  placeholder="Email ID"
                />
                <Button htmlType="submit" loading={loading}>
                  Send OTP
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
    </Row>
  );
}

export default ForgotPassword;
