import Sider from "antd/lib/layout/Sider";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import logoutIcon from "../../../Assets/images/logout.png";
import vigroLogo from "../../../Assets/images/vigrologo.png";
import "./sidebar.scss";
import AuthService from "../../../services/AuthService/auth.service";
import { AuthContext } from "../../../context/AuthContext";
import SidebarComponents from "../SidebarComponents";
import { Skeleton } from "antd";

interface SidebarProps {}

const Sidebar = (props: SidebarProps) => {
  const { logout } = AuthService();
  const { authenticated } = AuthContext();
  const { pathname } = useLocation();
  const { loading, sidebar } = SidebarComponents();
  const {} = props;

  const getClassName = (route: string) => {
    const baseClassName = "sider__item";
    const additionalClassName = pathname.includes(route) ? "sider--active" : "";

    return baseClassName + " " + additionalClassName;
  };

  return authenticated ? (
    <Sider className="sider pr-0">
      <span className="sider__logo">
        <img src={vigroLogo} alt="" />
      </span>
      {loading ? (
        <Skeleton />
      ) : (
        sidebar?.map((item, index) =>
          item.condition === undefined || item.condition ? (
            <div key={index} className={getClassName(item.route)}>
              <Link to={item.route}>
                <img src={item.image} className="sider__item__icon" alt="" />
                <span className="text-white">{item.text}</span>
              </Link>
            </div>
          ) : null
        )
      )}
      <div className="sider__item">
        <Link onClick={logout} to={AppRoutes.LOGIN}>
          <img src={logoutIcon} className="sider__item__icon" alt="" />
          <span className="text-white">logout</span>
        </Link>
      </div>
    </Sider>
  ) : (
    <></>
  );
};

export default Sidebar;
