import React, { useEffect, useState } from "react";
import { Col, DatePicker, Row, Select, Skeleton, Spin } from "antd";
import "./repaymentDashboard.scss";
import useBranch from "../../../shared/hooks/useBranch";
import { RepaymentService } from "../../../services/Repayment/repayment.service";
import {
  RepaymentDashboard,
  RepaymentsParams,
} from "../../../models/repayments.model";
import { formatNumber } from "../../../shared/utils/formatNumber";
import { DateFilter } from "../../../enums/dateFilter.type";
import RepaymentChart from "./RepaymentChart";
import portfolioOptions from "../../../shared/Constant/portFolioOptions";

import dateFilterOptions from "../../../shared/Constant/DateFilterOptions";
import CustomModal from "../../../shared/components/CustomModal";
import { dateTimeFormatter } from "../../../shared/utils/formatter";
import { DateFormat } from "../../../enums/dateFormat.type";
import { RepaymentChart as RepaymentChartmodel } from "../../../models/repaymentChart.model";

interface RangeDate {
  startDate: string;
  endDate: string;
}

const RepaymentsDashboard = () => {
  const { RangePicker } = DatePicker;
  const { branches } = useBranch({ allBranch: true });
  const {
    getRepaymentsDashboardMetrics,
    getRepaymentsDashboardGraph,
    loading,
  } = RepaymentService();
  const [metricsData, setMetricsData] = useState<RepaymentDashboard>();
  const [graphData, setgraphData] = useState<RepaymentChartmodel>();
  const [dashboardFilters, setDashboardFilters] = useState<RepaymentsParams>();
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [date, setDate] = useState<RangeDate>();

  // useEffect(() => {
  //   setDashboardLoading(true);
  // }, []);

  // useEffect(() => {
  //   handleRepaymentsDashboardMetrics(dashboardFilters);
  // }, [
  //   dashboardFilters?.date,
  //   dashboardFilters?.branch,
  //   dashboardFilters?.startDate,
  //   dashboardFilters?.endDate,
  // ]);

  // useEffect(() => {
  //   handleRepaymentsDashboardGraph(dashboardFilters);
  // }, [dashboardFilters?.graphBranch, dashboardFilters?.type]);

  const handleRepaymentsDashboardMetrics = async (
    params?: RepaymentsParams
  ) => {
    const metrics = await getRepaymentsDashboardMetrics(params);
    setMetricsData(metrics);
    setDashboardLoading(false);
  };
  const handleRepaymentsDashboardGraph = async (params?: RepaymentsParams) => {
    const graph = await getRepaymentsDashboardGraph(params);
    setgraphData(graph);
  };

  const statsValues = [
    {
      label: "Total Repayments",
      value: metricsData?.totalRepayment,
      total: `${metricsData?.totalRepaymentCount ?? 0} Loans`,
    },
    {
      label: "Own Repayments",
      value: metricsData?.ownRepayment,
      total: `${metricsData?.ownRepaymentCount ?? 0} Loans`,
    },
    {
      label: "Managed Repayments",
      value: metricsData?.managedRepayment,
      total: `${metricsData?.managedRepaymentCount ?? 0} Loans`,
    },
    {
      label: "Overdue",
      value: metricsData?.overdue,
      total: `${metricsData?.overdueCount ?? 0} Customer`,
    },
    {
      label: "New Overdue Customers",
      value: metricsData?.newOverdueCustomer,
      total: `${metricsData?.newOverdueCustomersCount ?? 0} Customer`,
    },
    {
      label: "Pre Close Amount",
      value: metricsData?.precloseRepayment,
      total: `${metricsData?.preCloseCount ?? 0} Loans`,
    },
    {
      label: "Overdue %",
      value: metricsData?.overduePercentage,
      total: `${metricsData?.overdueCustomerPercentage ?? 0}% Customer`,
    },
    {
      label: "New Overdue %",
      value: metricsData?.newOverduePercentage,
      total: `${metricsData?.newOverdueCustomersPercentage ?? 0}% Customer`,
    },
    {
      label: "Advance Repayment",
      value: metricsData?.advanceRepayment,
      total: `${metricsData?.advanceRepaymentCount ?? 0} Customer`,
    },
  ];

  const handleRangeDate = (_: unknown, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;
    setDate({ startDate, endDate });
  };

  const handleModalVisibility = () => setIsCustomDate(!isCustomDate);
  return (
    <>
      {dashboardLoading && loading ? (
        <div className="text-center">
          <Spin />
        </div>
      ) : (
        <Row className="repayment-dashboard">
          <Col className=" repayment-dashboard__stats card" span={14}>
            <Row gutter={[20, 20]}>
              <Col span={10}>
                <div className="repayment-dashboard__stats-label">Filter by Branch</div>
                <Select
                  placeholder="Select Branch"
                  options={branches}
                  onChange={(value) =>
                    setDashboardFilters({
                      ...dashboardFilters,
                      branch: value,
                    })
                  }
                  allowClear
                />
              </Col>
              <Col span={10} className="mb-5">
                <div className="repayment-dashboard__stats-label">
                  Filter by Time Period{" "}
                  {dashboardFilters?.startDate && dashboardFilters?.endDate && (
                    <span className="ml-5">{`(${dashboardFilters?.startDate} - ${dashboardFilters?.endDate})`}</span>
                  )}
                </div>
                <Select
                  placeholder="Select Time Period"
                  options={dateFilterOptions}
                  onChange={(value) => {
                    if (value === DateFilter.CUSTOM) setIsCustomDate(true);
                    else
                      setDashboardFilters({
                        ...dashboardFilters,
                        date: value,
                        startDate: undefined,
                        endDate: undefined,
                      });
                  }}
                  allowClear
                />

                {isCustomDate && (
                  <Col span={24}>
                    <CustomModal
                      className="repayment-dashboard__modal"
                      title="Select Date"
                      visible={isCustomDate}
                      onCancel={handleModalVisibility}
                      onOk={() => {
                        const filter = { ...dashboardFilters, ...date };
                        console.log({ filter });

                        handleModalVisibility();
                        setDashboardFilters(filter);
                      }}
                    >
                      <div>
                        <RangePicker
                          format={"DD/MM/YYYY"}
                          onChange={handleRangeDate}
                        />
                      </div>
                    </CustomModal>
                  </Col>
                )}
              </Col>
            </Row>

            <Row
              className="repayment-dashboard__stats-row mt-5"
              gutter={[0, 12]}
            >
              {statsValues?.map((data) => {
                return (
                  <Col className="repayment-dashboard__stats-box ">
                    <p className="repayment-dashboard__stats-box-label">
                      {data?.label}
                    </p>
                    {!metricsData && dashboardLoading ? (
                      <Skeleton active />
                    ) : (
                      <>
                        <h1 className="repayment-dashboard__stats-box-heading">
                          {formatNumber(data?.value)}
                        </h1>
                        <p className="repayment-dashboard__stats-box-description">
                          {data?.total}
                        </p>
                      </>
                    )}
                  </Col>
                );
              })}
            </Row>
          </Col>
          {graphData && (
            <Col className=" card repayment-dashboard__chart " span={9}>
              <Row gutter={[20, 20]}>
                <Col span={12}>
                  <div className="repayment-dashboard__chart-label mb-4">
                    Branch
                  </div>
                  <Select
                    placeholder="Select Branch"
                    options={branches}
                    onChange={(value) =>
                      setDashboardFilters({
                        ...dashboardFilters,
                        graphBranch: value,
                      })
                    }
                    allowClear
                  />
                </Col>
                <Col span={12} className="mb-5">
                  <div className="repayment-dashboard__chart-label mb-4">
                    Portfolio Type
                  </div>
                  <Select
                    placeholder="Select Portfolio Type"
                    options={portfolioOptions}
                    onChange={(value) => {
                      setDashboardFilters({
                        ...dashboardFilters,
                        type: value,
                      });
                      if (value === DateFilter.CUSTOM) setIsCustomDate(true);
                    }}
                    allowClear
                  />
                </Col>
              </Row>

              <RepaymentChart data={graphData} />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default RepaymentsDashboard;
