import { ColumnProps } from "antd/lib/table";
import React from "react";
import { DateFormat } from "../../../../enums/dateFormat.type";

import {
  LoanWallet,
  WalletTransactions,
} from "../../../../models/loanWallet.model";
import { dateTimeFormatter } from "../../../../shared/utils/formatter";

export const LoanWalletColumn: ColumnProps<WalletTransactions>[] = [
  {
    title: "Amount",
    dataIndex: "amount",
  },

  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    render: (status) => {
      const className = "active-status--" + status?.split("_")?.join("-");
      return <span className={` text-capitalize  ${className}`}>{status}</span>;
    },
  },
  {
    title: "Date",
    dataIndex: "dateTime",
    render: (_, loan) =>
      dateTimeFormatter(loan?.dateTime ?? "", DateFormat.ORIGINAL_DATE),
  },
  {
    title: "Time",
    dataIndex: "dateTime",
    render: (_, loan) =>
      dateTimeFormatter(loan?.dateTime ?? "", DateFormat.ORIGINAL_TIME),
  },
];
