import { alias, list, object, primitive, serializable } from "serializr";
import { CustomerDocument } from "./customerDocument";
import moment from "moment";
import { EducationQualification } from "../enums/educationQualification.type";
import { CustomerOwnedEntity } from "./customerOwnedEntity";
import { FamilyMember } from "./familyMember.model";
import { Gender } from "../enums/gender.type";
import { CustomerCenter } from "./customerCenter";
import { Address } from "./address.model";
import { ExistingLoan } from "./existingLoan.model";
import { FamilyDetail } from "./FamilyDetail.model";
import { BankDetail } from "./bankDetail.model";
import { CreatedBy } from "./createdBy.model";
import { CustomerFamilyMember } from "./customerFamilyMember";
import { EntityEnum } from "../enums/entityCategory.type";
import { CustomerDetailsNew } from "./customerDetailsNew";
import { LoanBankDetail } from "./customerBankDetails";

export class CustomerDetail {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("firstname", primitive()))
  firstName?: string;

  @serializable(alias("lastname", primitive()))
  lastName?: string;

  @serializable(alias("isd_code", primitive()))
  isdCode?: string;

  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;
  @serializable(alias("customer_details", list(object(CustomerDetailsNew))))
  customerDetails: CustomerDetailsNew[] = [];

  @serializable(alias("documents", list(object(CustomerDocument))))
  documents: CustomerDocument[] = [];

  @serializable(alias("owned_entities", list(object(CustomerOwnedEntity))))
  ownedEntities: CustomerOwnedEntity[] = [];

  @serializable(alias("family_members", list(object(CustomerFamilyMember))))
  familyMembers: CustomerFamilyMember[] = [];

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;

  @serializable(alias("gender", primitive()))
  gender?: Gender;

  @serializable(alias("is_active", primitive()))
  isActive?: boolean;

  @serializable(alias("center", object(CustomerCenter)))
  center?: CustomerCenter;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("pan_number", primitive()))
  panNumber?: string;

  @serializable(alias("pan_document_id", primitive()))
  panDocumentId?: string;

  @serializable(alias("pan_document_url", primitive()))
  panDocumentUrl?: string;

  @serializable(alias("educational_qualification", primitive()))
  educationalQualification?: EducationQualification;

  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;

  @serializable(alias("occupation", primitive()))
  occupation?: string;

  @serializable(alias("occupation_details", primitive()))
  occupationDetails?: string;

  @serializable(alias("monthly_income", primitive()))
  monthlyIncome?: number;

  @serializable(alias("business_income", primitive()))
  businessIncome?: number;

  @serializable(alias("address", object(Address)))
  address = new Address();

  @serializable(alias("bank_detail", list(object(LoanBankDetail))))
  bankDetails: LoanBankDetail[] = [];

  @serializable(alias("organisation_id", primitive()))
  organisationId?: number;

  @serializable(alias("organisation_ids", list(primitive())))
  organisationIds?: number[];

  @serializable(alias("profile_pic_id", primitive()))
  profilePicId?: string;

  @serializable(alias("family_detail", object(FamilyDetail)))
  familyDetail = new FamilyDetail();

  @serializable(alias("existing_loans", list(object(ExistingLoan))))
  existingLoans: ExistingLoan[] = [];

  @serializable(alias("organisation_name", primitive()))
  organizationName?: string;

  @serializable(alias("aadhaar_front_image_url", primitive()))
  aadhaarFrontImageUrl?: string;
  @serializable(alias("aadhaar_back_image_url", primitive()))
  aadhaarBackImageUrl?: string;
  @serializable(alias("cro_name", primitive()))
  croName?: string;

  @serializable(alias("created_by", object(CreatedBy)))
  createdBy?: CreatedBy;

  @serializable(alias("organisation", object(CustomerCenter)))
  organisation?: CustomerCenter;

  @serializable(alias("is_email_verified", primitive()))
  isEmailVerified?: boolean;

  @serializable(alias("is_mobile_verified", primitive()))
  isMobileVerified?: boolean;

  @serializable(alias("relationship", primitive()))
  relationship?: string;
  @serializable(alias("location", primitive()))
  location?: string;

  @serializable(alias("additonal_income", primitive()))
  additonalIncome?: string;

  @serializable(alias("is_aadhaar_verified", primitive()))
  isAadhaarVerified?: boolean;

  getEmail = () => this.email ?? "";

  getPhoneNumber = () => {
    const isdCode = this.isdCode ?? "+91";
    const phoneNumber = this.phoneNumber ?? "";
    return phoneNumber ? `${isdCode} ${phoneNumber}` : "NA";
  };

  getDOB = () =>
    moment(this.dob, "YYYY-MM-DD").isValid()
      ? moment(this.dob, "YYYY-MM-DD").format("D MMM YYYY")
      : "NA";

  getAge = () => {
    const age = moment().diff(moment(this.dob, "YYYY-MM-DD"), "years");
    return isNaN(age) ? "NA" : `${age} years`;
  };

  getStatus = () => (this.isActive ? "active" : "inactive");
  getName = () =>
    this.firstName ? `${this.firstName} ${this.lastName ?? ""}  ` : "";

  getEducationalQualification = () => {
    switch (this.educationalQualification) {
      case EducationQualification.HIGHER_SECONDARY:
        return "higher secondary";
      case EducationQualification.OTHER_QUALIFICATION:
        return "other educational qualification";
      default:
        return this.educationalQualification ?? "";
    }
  };

  getAadhaarNumber = () => this.aadhaarNumber;

  getPanNumber = () => this.panNumber;

  getNominee = () =>
    this?.familyMembers
      ?.at?.(-1)
      ?.loanFamilyMembers?.find?.((member) => member?.isNominee);

  getCoBorrower = () =>
    this?.familyMembers
      ?.at(-1)
      ?.loanFamilyMembers?.find((member) => member?.isCoBorrower);

  getEntityByCategory = (category: EntityEnum) =>
    this?.ownedEntities
      ?.at(-1)
      ?.loanOwnedEntities?.filter(
        (entity) => entity?.entityCategory === category
      );

  getAppliances = () => this.getEntityByCategory(EntityEnum.APPLIANCE);

  getCattles = () => this.getEntityByCategory(EntityEnum.CATTLE);

  getOtherProperties = () => this.getEntityByCategory(EntityEnum.OTHER);

  getInvestments = () => this.getEntityByCategory(EntityEnum.INVESTMENT);

  getVehicles = () => this.getEntityByCategory(EntityEnum.VEHICLE);
}
