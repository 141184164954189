import React, { PropsWithChildren, useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./branches.scss";

import * as AppRoutes from "../../../routes/routeConstants/appRoutes";

import { Branch, BranchParams } from "../../../models/branch.model";
import { Button, Drawer, Popover, TableColumnsType, TableProps } from "antd";

import { generatePath, useNavigate } from "react-router-dom";

import BranchFilters from "./BranchFilters";
import BranchDetailForm from "./BranchDetailForm";
import { BranchesService } from "../../../services/Branches/branches.service";
import { QueryParams } from "../../../models/query.params.model";
import { SorterResult } from "antd/lib/table/interface";
import Table from "../../../shared/components/Table";
import { ToggleDrawer } from "../../../shared/types/toggleDrawer";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import useSorting from "../../../shared/hooks/useSorting";
import { updatedPageOnItemAddition } from "../../../shared/utils/helpers";
import addIcon from "../../../Assets/images/add.png";
import editIcon from "../../../Assets/images/edit.png";
import BranchTargetForm from "./BranchTargetForm";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { StepStatus } from "../../../enums/stepStatus.enum";
import BranchCollectionDueForm from "./BranchCollectionDueForm";
import BranchPinLocationForm from "./BranchPinLocationForm";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { TableConfig } from "../../../enums/tableConfig.type";
import BranchStepWrapper from "./BranchStepWrapper";
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import TabExtraContent from "../../../shared/components/TabExtraContent";

const sortBy = {
  code: "code",
  name: "name",
  email: "email",
  mobileNumber: "mobile_number",
  responsibleEmployeeName: "responsible_employee_firstname",
  regionName: "region_name",
  isActive: "is_active",
};

interface BranchesProps {
  params?: BranchParams;
  addFormVisibility: boolean;
  filtersVisibility: boolean;
  targetFormVisibility: boolean;
  settingsVisibility: boolean;
  toggleAddForm: ToggleDrawer;
  toggleFilters: ToggleDrawer;
  toggleTargetForm: ToggleDrawer;
  toggleSettings: ToggleDrawer;
}

const Branches: React.FC<PropsWithChildren<BranchesProps>> = (props) => {
  const {
    addFormVisibility,
    filtersVisibility,
    targetFormVisibility,
    settingsVisibility,
    toggleAddForm,
    toggleFilters,
    toggleTargetForm,
    toggleSettings,
  } = props;

  const {
    getBranches,
    addBranch,
    loading,
    updateBranchTarget,
    getBranchTarget,
  } = BranchesService();

  const { updateSortData } = useSorting<Branch>({ sortBy });

  const { params, updateParams, getParams, handleSearch: handleBranchSearch } = useQueryParams({
    params: new BranchParams(),
  });

  const navigate = useNavigate();

  const [branches, setBranches] = useState<Branch[]>([]);

  const [totalBranch, setTotalBranch] = useState(1);

  const [targetBranch, setTargetBranch] = useState<Branch>();
  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState<Branch>(new Branch());
  const [filters, setFilters] = useState<Filters>(new Filters());
  const { columnConfig, setColumnConfig } = useTableConfig();
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );

  const columns: TableColumnsType<Branch> = [
    {
      title: "Branch ID",
      dataIndex: "code",
      sorter: true,
    },
    {
      title: "Branch Name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Phone",
      dataIndex: "mobileNumber",
      sorter: true,

      render: (_, record) => record?.getPhoneNumber(),
    },
    {
      title: "BM Name",
      dataIndex: "responsibleEmployeeName",
      sorter: true,

      render: (_, record: any) =>
        record.responsibleEmployeeFirstName &&
        record.responsibleEmployeeFirstName
          ? record.responsibleEmployeeFirstName +
            "   " +
            record.responsibleEmployeeLastName
          : "",
    },
    {
      title: "Geo Location",
      dataIndex: "regionName",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      sorter: true,

      render: (isActive: boolean) =>
        isActive ? (
          <span className="active-status text-capitalize text-success">
            active
          </span>
        ) : (
          <span className="active-status text-capitalize text-danger">
            inactive
          </span>
        ),
    },
    {
      width: 90,
      render: (isActive: boolean, branch: Branch, index: number) => (
        <span className="branch-actions actions">
          {isActive && userAccess?.writeAccess ? (
            <img
              src={addIcon}
              alt=""
              width={28}
              className="target-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleBranchTarget(branch);
                toggleTargetForm();
              }}
            />
          ) : null}
          {userAccess?.writeAccess && (
            <Popover
              placement="bottom"
              content={
                <>
                  <div className="branch__popover-content">
                    <img src={editIcon} alt="" width={18} />
                    Edit
                  </div>
                </>
              }
              trigger="click"
            >
              <Button onClick={(e) => e.stopPropagation()}>
                {/* More options (three dots) icon needed */}
                <img src={editIcon} alt="" width={24} />
              </Button>
            </Popover>
          )}
        </span>
      ),
    },
  ];

  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Branch>>(columns);

  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.branches ?? []
  );

  useEffect(() => {
    const updateParams = params?.searchText
    ? { searchText: params.searchText, page: params.page }
    : params
    populateBranches(updateParams);
  }, [params, params?.searchText]);

  useEffect(() => {
    setColumnConfig(TableConfig.BRANCHES, filteredColumns);
  }, [filteredColumns]);

  const setBranchTarget = async (branch: Branch) => {
    const params = {
      year: branch?.year,
      month: branch?.month,
      target: Number(branch?.target),
    };

    // const updatedParam = Object.assign(new Branch(), params);
    const branchTarget = await updateBranchTarget(
      params as Branch,
      branch?.id ?? 0
    );
    if (branchTarget) toggleTargetForm();
  };

  const updateTotalBranch = (totalPage?: number) =>
    setTotalBranch(totalPage || 1);

  const handleChange: TableProps<Branch>["onChange"] = async (
    pagination,
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<Branch>
    );
    updateParams({
      ...params,
      page: pagination?.current,
      sortBy,
      sortDirection,
    });
  };

  const handleRowChange = (record: Branch) => ({
    onClick: () =>
      record?.id &&
      navigate(
        generatePath(AppRoutes.BRANCH_DETAIL, {
          id: String(record?.id),
        })
      ),
  });

  const handleBranchAdd = async (branch: Branch) => {
    const newBranch = await addBranch(branch);
    if (newBranch) {
      const updatedPage = updatedPageOnItemAddition(totalBranch);

      if (params.page !== updatedPage) {
        updateParams({ ...params, page: updatedPage });
      } else {
        setBranches((branches) => [...branches, newBranch]);
        setTotalBranch((totalBranch) => ++totalBranch);
      }
      toggleAddForm();
    }
  };

  const populateBranches = async (params: QueryParams) => {
    const branchesData = await getBranches(params);
    if (branchesData?.branches) setBranches(branchesData?.branches);
    if (branchesData?.meta) updateTotalBranch(branchesData?.meta?.totalCount);
    if (branchesData?.meta.filters) setFilters(branchesData?.meta.filters)
  };

  const handleBranchTarget = async (branch: Branch, formValue?: Branch) => {
    !formValue?.id && setTargetBranch(branch);
    const params = { month: formValue?.month, year: formValue?.year };
    if (formValue?.id) {
      const target = await getBranchTarget(params, formValue?.id);

      target && setTargetBranch({ ...formValue, target: target?.target });
      return target?.target;
    }
  };

  const handleUpdateParams = () => updateParams(getParams());

  return (
    <div className="branches">
      {!loading && filters && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <div className="branches-extra-content">
        <TabExtraContent
          searchProps={{
            value: params.searchText,
            onSearch: handleBranchSearch,
            placeholder: "Search Branch",
          }}
        />
      </div>
      <Table
        dataSource={branches}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        loading={loading}
        onChange={handleChange}
        rowKey="id"
        showSorterTooltip={false}
        pagination={{
          current: params?.page,
          total: totalBranch,
        }}
        onRow={handleRowChange}
      />
      <Drawer
        placement="right"
        onClose={() => {
          setCurrentStep(1);
          toggleAddForm(false);
        }}
        visible={addFormVisibility}
        width="916"
        title="New Branch"
        destroyOnClose
      >
        <BranchStepWrapper
          formValues={formValues}
          onSubmit={handleBranchAdd}
          onClose={() => toggleAddForm(false)}
        />
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleFilters()}
        visible={filtersVisibility}
        width="916"
        title="Branch Filters"
        destroyOnClose
      >
        <BranchFilters onClose={toggleFilters} onFilter={updateParams} />
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleTargetForm()}
        visible={targetFormVisibility}
        width={720}
        title="Set Target"
        destroyOnClose
      >
        <BranchTargetForm
          formValues={targetBranch}
          onSubmit={setBranchTarget}
          onClose={toggleTargetForm}
          getBranchTarget={(branch: Branch) =>
            handleBranchTarget(new Branch(), branch)
          }
        />
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleSettings()}
        visible={settingsVisibility}
        width={500}
        title="Column Options"
        destroyOnClose
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleSettings()}
          setDefaultColumns={setDefaultColumns}
        />
      </Drawer>
    </div>
  );
};
export default Branches;
