export enum Months {
    JAN = "Jan",
    FEB = "Feb",
    MAR = "Mar",
    APR = "Apr",
    MAY = "May",
    JUN = "Jun",
    JUL = "Jul",
    AUG = "Aug",
    SEP = "Sep",
    OCT = "Oct",
    NOV = "Nov",
    DEC = "Dec",
}