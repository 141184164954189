import { Layout } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../shared/components/Sidebar";
import Navbar from "../../shared/components/NavBar";

import "./appLayout.scss";

interface AppLayoutProps {}

const AppLayout = (props: AppLayoutProps) => {
  const {} = props;

  return (
    <div className="app-layout">
      <Layout hasSider>
        <Sidebar />
        <Layout style={{ marginLeft: "200px" }}>
          <Header>
            <Navbar />
          </Header>
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AppLayout;
