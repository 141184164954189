import "antd/dist/antd.css";
import "./users.scss";

import { Button, Col, Drawer, Row, TableColumnsType, TableProps } from "antd";
import { Employee, EmployeeParams } from "../../models/employee.model";
import React, { HTMLAttributes, useEffect } from "react";

import { EmployeeService } from "../../services/Employee/employee.service";
import { SorterResult } from "antd/lib/table/interface";
import TabExtraContent from "../../shared/components/TabExtraContent";
import Table from "../../shared/components/Table";
import { User } from "../../models/user.model";
import UserFilters from "./UserFilters";
import UserForm from "./UserForm";
import { columns } from "./usersColumn";
import useDrawer from "../../shared/hooks/useDrawer";
import useQueryParams from "../../shared/hooks/useQueryParams";
import useSorting from "../../shared/hooks/useSorting";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import { updatedPageOnItemAddition } from "../../shared/utils/helpers";
import ColumnOptions from "../../shared/components/ColumnOptions";
import useTableConfig from "../../shared/hooks/useTableConfig";
import { TableConfig } from "../../enums/tableConfig.type";
import OfflineLoanForm from "./OfflineLoanForm";
import CustomModal from "../../shared/components/CustomModal";
import offlineIcon from "../../Assets/images/offline.png";
import { Filters } from "../../models/filters.model";
import FilterButtons from "../../shared/components/FilterButtons";
import CustomRangePicker from "../../shared/components/CustomRangePicker";
import { DateRange } from "../../shared/types/date.type";
import { UserAccess } from "../../models/userAccess.model";
import { LocalStorageHelper } from "../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../enums/localStorage.enum";
const sortBy = {
  roleId: "role_id",
  code: "code",
  email: "email",
  firstName: "firstname",
  phoneNumber: "phone_number",
  isActive: "is_active",
  branches: "branches.name",
};

const Users = () => {
  const { getEmployees, addEmployee, updateEmployeeOfflineLoan, loading } =
    EmployeeService();
  const { columnConfig, setColumnConfig } = useTableConfig();

  const { params, updateParams, handleSearch, removeAllParams, getParams } =
    useQueryParams<EmployeeParams>({
      params: new EmployeeParams(),
    });
  const { visible: userFormVisible, toggleVisibility: toggleUserForm } =
    useDrawer({});

  const { visible: userFilter, toggleVisibility: toggleUserFilter } = useDrawer(
    {}
  );

  const { visible: userSettingsVisible, toggleVisibility: toggleUserSettings } =
    useDrawer({});

  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  ) as UserAccess;

  const navigate = useNavigate();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([]);

  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState<Filters>(new Filters());

  const [isOfflineLoan, setIsOfflineLoan] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { sortData, updateSortData } = useSorting<User>({ sortBy });
  const [dateRange, setDateRange] = useState<DateRange>();

  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Employee>>(columns);

  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.users ?? []
  );

  const handleAddEmployee = async (employee: Employee) => {
    const newEmployee = await addEmployee(employee);

    if (newEmployee) {
      const updatedPage = updatedPageOnItemAddition(totalPages);
      if (params.page !== updatedPage) {
        updateParams({ ...params, page: updatedPage });
      } else {
        setEmployees((employees) => [...employees, newEmployee]);
        setTotalPages((pages) => ++pages);
      }
      toggleUserForm();
    }
  };

  const handleChange: TableProps<User>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<User>
    );
    updateParams({
      ...params,
      page: pagination?.current,
      sortBy,
      sortDirection,
    });
  };

  const handleRowChange = (employee: Employee): HTMLAttributes<Employee> => ({
    onClick: () => {
      const employeeDetailRoute = generatePath(AppRoutes.USER_DETAILS, {
        userId: employee?.id ? String(employee?.id) : "",
      });
      navigate(employeeDetailRoute);
    },
  });

  const populateEmployees = async (params?: EmployeeParams) => {
    const employeesData = await getEmployees(params);
    if (employeesData?.employees) {
      setEmployees(employeesData?.employees);
      setIsOfflineLoan(false);
    }

    if (employeesData?.meta) {
      setTotalPages(Number(employeesData?.meta?.totalCount));
      employeesData?.meta?.filters && setFilters(employeesData?.meta?.filters);
    }
  };

  useEffect(() => {
    populateEmployees({
      ...params,
      ...dateRange,
    });
  }, [params, sortData, dateRange]);

  useEffect(() => {
    setColumnConfig(TableConfig.USERS, filteredColumns);
  }, [filteredColumns]);

  const toggleOfflineLoanVisibility = () => setIsOfflineLoan(!isOfflineLoan);

  const rowSelection = {
    selectedRowKeys: selectedEmployees,

    onChange: (selectedRowKeys: React.Key[], employee: Employee[]) => {
      const ids = employee.map((employee) => employee?.id ?? 0);

      handleSelectedEmployee(ids);
    },
  };
  const handleSelectedEmployee = (employee: number[]) =>
    setSelectedEmployees(employee);

  const handleOfflineLoanSuccess = (
    employee: Employee[],
    values: EmployeeParams,
    totalPage: number
  ) => {
    populateEmployees(values);
    setEmployees(employee);
    setTotalPages(totalPage);
    updateParams({ ...values, stepNo: 1 });
  };

  const handleModalVisibility = () => setModalVisible(!modalVisible);
  const handleOfflineLoan = async () => {
    const loan = await updateEmployeeOfflineLoan(selectedEmployees);
    if (loan) {
      removeAllParams();
      handleModalVisibility();
    }
  };

  const handleDataSource = () => {
    if (params?.stepNo == 1)
      return employees.filter((employee) => !employee.offlinePermissionEnabled);
    return employees;
  };

  const handleUpdateParams = () => updateParams(getParams());
  const handleDateChange = ({ toDate, fromDate }: DateRange) => {
    updateParams({ ...params, toDate, fromDate });
  };

  return (
    <div className="users">
      <div className="users__header">
        {!params?.stepNo ? (
          <Row>
            <CustomRangePicker
              className="mr-5 custom-range-picker"
              onChange={handleDateChange}
              values={{ toDate: params?.toDate, fromDate: params?.fromDate }}
            />
            {userAccess?.writeAccess && (
              <Button
                className="btn-header  mr-5"
                onClick={toggleOfflineLoanVisibility}
              >
                <img src={offlineIcon} height={18} width={18} /> OFFLINE LOAN
                JOURNEY
              </Button>
            )}
            <TabExtraContent
              addClick={userAccess?.writeAccess ? toggleUserForm : null}
              searchProps={{
                onSearch: handleSearch,
                placeholder: "Search User",
              }}
              // exportClick={() => {}}
              filterClick={toggleUserFilter}
              settingsClick={toggleUserSettings}
            />
          </Row>
        ) : (
          <Row>
            <Col span={12}>
              <Button
                className="btn-header  mr-5"
                onClick={handleModalVisibility}
                disabled={!selectedEmployees?.length}
              >
                CONFIRM OFFLINE LOAN JOURNEY
              </Button>
            </Col>{" "}
          </Row>
        )}
      </div>
      {!loading && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <Table
        rowKey="id"
        className="custom-scrollbar"
        scroll={{ x: true }}
        loading={loading}
        dataSource={handleDataSource()}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        rowSelection={
          params?.stepNo === 1
            ? {
                preserveSelectedRowKeys: true,
                type: "checkbox",
                ...rowSelection,
              }
            : undefined
        }
        onChange={handleChange}
        onRow={params?.stepNo === 1 ? undefined : handleRowChange}
        pagination={{
          current: params?.page,
          hideOnSinglePage: true,
          total: totalPages,
        }}
      />
      <Drawer
        placement="right"
        onClose={() => toggleUserForm(false)}
        visible={userFormVisible}
        width="916"
        title="Add User"
        closable
        destroyOnClose
      >
        <UserForm onClose={toggleUserForm} onSubmit={handleAddEmployee} />
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleUserFilter(false)}
        visible={userFilter}
        width={"70vw"}
        title="Filters"
        closable
        destroyOnClose
      >
        <UserFilters onFilter={updateParams} onClose={toggleUserFilter} />
      </Drawer>
      <Drawer
        placement="right"
        onClose={toggleOfflineLoanVisibility}
        open={isOfflineLoan}
        width="700"
        title="Enable Offline Loan Journey"
        closable
        destroyOnClose
      >
        <OfflineLoanForm
          onClose={toggleOfflineLoanVisibility}
          onSuccess={handleOfflineLoanSuccess}
        />
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleUserSettings()}
        visible={userSettingsVisible}
        width={500}
        title="Column Options"
        destroyOnClose
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleUserSettings()}
          setDefaultColumns={setDefaultColumns}
        />
      </Drawer>
      <CustomModal
        onCancel={handleModalVisibility}
        title="Offline Loan Journey"
        visible={modalVisible}
        onOk={handleOfflineLoan}
        onClose={handleModalVisibility}
        okText="Enable"
      >
        {selectedEmployees?.length} users are selected to enable the offline
        loan journey.
      </CustomModal>
    </div>
  );
};
export default Users;
