import React from "react";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { DateRange } from "../../types/date.type";

interface RangePickerProps {
  onChange: (dateRange: DateRange) => void;
  className?: string;
  values?: DateRange;
  submittedDate?: boolean;
  approvedDate?: boolean;
}

const CustomRangePicker: React.FC<RangePickerProps> = ({
  onChange,
  className,
  values,
  submittedDate,
  approvedDate
}) => {
  const handleRangeChange = (_: unknown, dateStrings: [string, string]) => {
    if (submittedDate) {
      onChange({ submittedFromDate: dateStrings[0], submittedToDate: dateStrings[1] });
    } else if(approvedDate) {
      onChange({ approvedFromDate: dateStrings[0], approvedToDate: dateStrings[1]})
    } else {
      onChange({ fromDate: dateStrings[0], toDate: dateStrings[1] });
    }
  };
  
const value: [Moment, Moment] | undefined =
  values?.submittedFromDate && values?.submittedToDate
    ? [
        moment(values.submittedFromDate, "DD/MM/YYYY"),
        moment(values.submittedToDate, "DD/MM/YYYY"),
      ]
    : values?.fromDate && values?.toDate
    ? [
        moment(values.fromDate, "DD/MM/YYYY"),
        moment(values.toDate, "DD/MM/YYYY"),
      ]
    : values?.approvedFromDate && values?.approvedToDate
    ? [
      moment(values.approvedFromDate, "DD/MM/YYYY"),
      moment(values.approvedToDate, "DD/MM/YYYY")
    ]
    : undefined
  
  return (
    <DatePicker.RangePicker
      className={className}
      format={"DD/MM/YYYY"}
      placeholder={["dd/mm/yy", "dd/mm/yy"]}
      onChange={handleRangeChange}
      value={value}
    />
  );
};

export default CustomRangePicker;
