import * as Yup from "yup";
import { UserRole } from "../../../enums/userRoles";

export const userFormValidation = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(25, "Maximum 25 characters")
    .required("First Name is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .nullable(),
  lastName: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .required("Last Name is required").nullable(),
  code: Yup.string()
    .min(2, "Minimum two characters")
    .max(25, "Maximum characters exceeded")
    .required("Employee ID is required").nullable(),
  gender: Yup.string().required("Gender required").nullable(),
  email: Yup.string().required().email().label("Email").nullable(),
  phoneNumber: Yup.string()
    .required()
    .matches(/^[6-9]\d{9}$/gi, "Phone number is not valid")
    .label("Phone Number").nullable(),
  address: Yup.object().shape({
    addressLine1: Yup.string().required().label("Address 1").nullable(),
    addressLine2: Yup.string().nullable().label("Address 2").nullable(),
    stateId: Yup.string().required().label("State").nullable(),
    cityId: Yup.string().required().label("City").nullable(),
    pincode: Yup.string()
      .required()
      .min(6, "Must be 6 digits")
      .max(6, "Must be 6 digits")
      .label("Pincode").nullable(),
    landmark: Yup.string().nullable().label("Landmark").nullable(),
  }),
  roleId: Yup.string().required().label("Role").nullable(),
  branchIds: Yup.array()
    .label("Branches")
    .when(["role", "roleId"], {
      is: (role: UserRole, roleId: number) => {
        if ((role && (role === UserRole.BRANCH_ACCOUNTANT || role === UserRole.BRANCH_MANAGER || role === UserRole.CUSTOMER_REPRESENTATIVE || role === UserRole.CREDIT_OFFICER || role === UserRole.BRANCH_MIS ))) return true;
        return false;
      },
      then: (schema: any) => schema.required().nullable(),
      otherwise: (schema: any) => schema.notRequired().nullable(),
    }),
  centerIds: Yup.array()
    .label("Centers")
    .when(["role", "roleId"], {
      is: (role: UserRole, roleId: number) => {
        if (
          (role && role === UserRole.CUSTOMER_REPRESENTATIVE)
        )
          return true;
        return false;
      },
      then: (schema: any) => schema.required().nullable(),
      otherwise: (schema: any) => schema.notRequired().nullable(),
    }),
    organisationIds: Yup.array().label("Preferred Channel").when(["role", "roleId"], {
      is: (role: UserRole) => {
        if (
          (role && role === UserRole.HEAD_OFFICER)
        )
        return true
      },
      then: (schema: any) => schema.required().nullable(),
      otherwise: (schema: any) => schema.notRequired().nullable(),
    })
});


