import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import dashboard from "../../../Assets/images/dashboard.png";
import ctVerification from "../../../Assets/images/ct-verification.png";
import User from "../../../Assets/images/User.png";
import customers from "../../../Assets/images/customers.png";
import pipelineIcon from "../../../Assets/images/pipeline.png";
import repayments from '../../../Assets/images/Repayments.svg'
import loans from "../../../Assets/images/loans.png";
import disbursement from '../../../Assets/images/Disbursement.svg'
import configurations from "../../../Assets/images/configurations.png";
import reports from "../../../Assets/images/reports.png";
import claims from '../../../Assets/images/claims.svg';
import useUserAccess from "../../hooks/useUserAccess";
import { RoleName } from "../../../enums/roleName.enum";

const SidebarComponents = () => {
  const { userAccess, loading } = useUserAccess();

  const sidebar = [
    {
      key: "bcLoans",
      route: AppRoutes.BRANCH_ACCOUNTANT_HOME,
      image: repayments,
      text: "BC Loans",
      condition: userAccess?.name === RoleName.BRANCH_ACCOUNTANT
    },
    {
      key: "home",
      route: AppRoutes.ACCOUTANT_HOME,
      image: dashboard,
      text: "home",
      condition: userAccess?.name === RoleName.ACCOUNTANT,
    },
    {
      key: "documents",
      route: AppRoutes.MOD_DOCUMENTS,
      image: reports,
      text: "documents",
      condition: userAccess?.name === RoleName.MOD_OFFICER
    },
    {
      key: "dashboardManagement",
      route: AppRoutes.DASHBOARD,
      image: dashboard,
      text: "dashboard",
      condition: userAccess?.dashboardManagement,
    },
    {
      key: "ctVerificationManagement",
      route: AppRoutes.CT_VERIFICATION,
      image: ctVerification,
      text: "CT Verification",
      condition: userAccess?.ctVerificationManagement,
    },
    {
      key: "userManagement",
      route: AppRoutes.USERS,
      image: User,
      text: "users",
      condition: userAccess?.userManagement,
    },
    {
      key: "customerConfiguration",
      route: AppRoutes.CUSTOMERS,
      image: customers,
      text: "customers",
      condition: userAccess?.customerConfiguration,
    },
    {
      key: "pipelineManagement",
      route: AppRoutes.PIPELINE,
      image: pipelineIcon,
      text: "Pipeline",
      condition: userAccess?.pipelineManagement,
    },
    {
      key: "loanManagement",
      route: AppRoutes.LOANS,
      image: loans,
      text: "loans",
      condition: userAccess?.loanManagement,
    },
    
    {
      key: "repaymentManagement",
      route: AppRoutes.REPAYMENTS,
      image: repayments,
      text: "Repayments",
      condition: userAccess?.repaymentManagement,
    },
    {
      key: "disbursement",
      route: AppRoutes.DISBURSEMENT,
      image: disbursement,
      text: "disbursement",
      condition: userAccess?.disbursement,
    },
    {
      key: "loanClaims",
      route: AppRoutes.LOAN_CLAIMS,
      image: claims,
      text: "claims",
      condition: userAccess?.name === RoleName.SUPER_ADMIN
    },
    {
      key: "configurationManagement",
      route: AppRoutes.CONFIGURATIONS,
      image: configurations,
      text: "configuration",
      condition:
        userAccess?.configurationManagement ||
        userAccess?.name === RoleName.SUPER_ADMIN,
    },
    {
      key: "reportManagement",
      route: AppRoutes.REPORTS,
      image: reports,
      text: "reports",
      condition: userAccess?.reportManagement,
    },
  ];

  return { sidebar, loading };
};

export default SidebarComponents;
