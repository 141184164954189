import React from "react";
import { Button, Col, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import "../auth.scss";
import InputField from "../../../shared/components/InputField";
import AuthService from "../../../services/AuthService/auth.service";

import { User } from "../../../models/user.model";
import { InputType } from "../../../enums/input.type";
import mainLogo from "../../../Assets/images/main-logo.png";

import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { loginValidation } from "./loginValidation";

function LoginDetails() {
  const { loading, login } = AuthService();
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    const employee = {
      email: values.emailId,
      password: values.password,
      grant_type: "password",
      is_mobile: false,
    };
    login(employee);
  };

  return (
    <Row className="login">
      <Col span={12} className="login__logo-container">
        <img src={mainLogo} alt="logo" />
      </Col>
      <Col span={12} className="content-container">
        <div className="login__form">
          <Formik
            initialValues={new User()}
            validationSchema={loginValidation}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {(props) => (
              <Form className="ant-form-vertical" onSubmit={props.handleSubmit}>
                <h1 className="login__form__heading">Login to VGro</h1>
                <InputField
                  label="User ID"
                  type={InputType.TEXT}
                  name="emailId"
                  placeholder="User ID"
                />
                <InputField
                  label="Password"
                  type={InputType.PASSWORD}
                  name="password"
                  placeholder="Password"
                />
                <div className="text-underline">
                  <a target="_blank" href={AppRoutes.TERMS_AND_CONDITIONS}>
                    Terms & Conditions
                  </a>
                </div>
                <Button htmlType="submit" loading={loading}>
                  Login
                </Button>
                <Link
                  className="login__forgot-password"
                  to={AppRoutes.FORGOT_PASSWORD}
                >
                  FORGOT PASSWORD?
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
    </Row>
  );
}

export default LoginDetails;
