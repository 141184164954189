//Icons

//Libraries
import { Spin } from "antd";
import React from "react";

//Styles
import "./appLoader.scss";

interface AppLoaderProps {
  loading: boolean;
}

const AppLoader = (props: AppLoaderProps) => {
  //Service

  //Context

  //Prop
  const { loading } = props;

  //State

  return (
    <div className="text-center app-loader w-100">
      <Spin spinning={loading} />
    </div>
  );
};

export default AppLoader;
