export enum LoanStatus {
  DRAFT = "draft",
  APPLIED = "applied",
  SUBMITTED = "submitted",
  APPROVED = "approved",
  PROGRESS = "progress",
  DISBURSED = "disbursed",
  REJECTED = "rejected",
  RE_SUBMIT = "re_submit",
  RE_APPLY = "re_apply",
  E_SIGN = "e_sign",
  PENDING = "pending",
  CT_VERIFICATION = "ct_verification",
  DISBURSEMENT_IN_PROGRESS = "Disbursement in Progress",
  DISBURSEMENT_IN_PROGRESS_GET = "disbursement_in_progress",
  PRE_CLOSED = 'pre_closed',
  COMPLETED = 'reviewed',
  LINKED = "linked",
  FOR_CLAIMS = "for_claims",
}
