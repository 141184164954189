import { Button, Col, Row } from "antd";
import React, { useRef } from "react";
import { Accountant } from "../../../models/accountant.model";
import { OptionArray } from "../../../shared/types/optionArray.type";
import {
  capitalizeWord,
  dateTimeFormatter,
} from "../../../shared/utils/formatter";
import { DateFormat } from "../../../enums/dateFormat.type";
import docIcon from "../../../Assets/images/docIcon.png";
import "../accountant.scss";
import { AccountantVerificationStatus } from "../../../enums/verificationStatus";
import { Formik, FormikProps } from "formik";
import { ChangeRegularisationStatus } from "../../../models/regularisation.model";
import repaymentRejectionValidation from "../repaymentRejectionValidation";
import CustomModal from "../../../shared/components/CustomModal";
import useDrawer from "../../../shared/hooks/useDrawer";
import InputField from "../../../shared/components/InputField";
import { InputType } from "../../../enums/input.type";
import { useLocation } from "react-router-dom";
import { LoanStatus } from "../../../enums/loanStatus.type";
import CustomDocument from "../../../shared/components/CustomDocument";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { getBackDateReason } from "../getBackDateReason";
import SelectInput from "../../../shared/components/SelectInput";
import rejectionReasons from "../../../shared/Constant/rejectionReasonOptions";
import { AccountantService } from "../../../services/Accountant/accountant.service";

interface RegularisationDetailsProps {
  loan?: Accountant;
  onClose: () => void;
  activeTab?: string;
  fetchLoans: () => void
}

const RegularisationDetails = (props: RegularisationDetailsProps) => {
  const formRef = useRef<FormikProps<ChangeRegularisationStatus>>(null);
  const { changeRegularisationStatus } = AccountantService();
  const handleApprove = async () => {
    const isApproved = await changeRegularisationStatus({
      regulariseIds: [loan?.id || 0],
      status: AccountantVerificationStatus.APPROVE,
    });
    
    isApproved && onClose();
    fetchLoans()
  };

  const handleReject = () => {
    toggleregularisationModalVisibility(true);
    fetchLoans()
  };

  const handleSubmit = async () => {
    const isRejected = await changeRegularisationStatus({
      regulariseIds: [loan?.id || 0],
      status: AccountantVerificationStatus.REJECT,
      rejectedReason: formRef?.current?.values.rejectedReason,
    });
    isRejected && onClose();
    fetchLoans()
  };

  const { loan, onClose, activeTab, fetchLoans } = props;
  const isNewRequest = loan?.status === LoanStatus.LINKED
  const loanDetails: OptionArray[] = [
    {
      label: "Loan Id",
      value: fillEmptyData(loan?.loanCode),
    },
    {
      label: "Customer Name",
      value: fillEmptyData(loan?.customerName),
    },
    {
      label: "CRO",
      value: fillEmptyData(loan?.croName),
    },
    {
      label: "Due Amount",
      value: fillEmptyData(loan?.dueAmount),
    },
    {
      label: "Due Date",
      value: fillEmptyData(loan?.dueDate),
    },
    {
      label: "Entry Date",
      value: dateTimeFormatter(loan?.entryDate ?? "", DateFormat.DATE),
    },
    {
      label: "Back Date",
      value: dateTimeFormatter(loan?.backDate ?? "", DateFormat.DATE),
    },
    {
      label: "Reason for Backdate",
      value: fillEmptyData(
        capitalizeWord(getBackDateReason(loan?.reason ?? ""))
      ),
    },
    {
      label: "Rejection Reason",
      value: fillEmptyData(loan?.rejectedReason)
    },
    {
      label: "Collection Proof",
      value: loan?.attachment ? (
        <>
          <div className="collection-proof-container mt-2">
            <div className="text-container">
              <span>
                <img src={docIcon} alt="" className="proof-icon " />
              </span>
              <CustomDocument
                label="Collection-Proof"
                src={loan?.attachment}
                onlyText={true}
              />
            </div>
          </div>
        </>
      ) : (
        "-"
      ),
    },
  ];

  const {
    visible: regularisationModalVisible,
    toggleVisibility: toggleregularisationModalVisibility,
  } = useDrawer({});

  return (
    <>
      <Row gutter={[16, 50]} className="regularisation-details">
        {loanDetails?.map((data, index) => {
          return (
            <>
              <Col span={8} className="block__label">
                <span> {data?.label}</span>
                <div className="block__value text-capitalize">
                  {data?.value}
                </div>
              </Col>
            </>
          );
        })}
      </Row>
      <Formik
        initialValues={new ChangeRegularisationStatus()}
        onSubmit={handleSubmit}
        validationSchema={repaymentRejectionValidation}
        innerRef={formRef}
      >
        {({ isValid, dirty, resetForm, values }) => {
          const handleCancel = () => {
            resetForm();
            toggleregularisationModalVisibility(false);
          };
          return (
            <CustomModal
              title="Enter Remarks for Rejection"
              okText="Reject Request"
              cancelText="Cancel"
              visible={regularisationModalVisible}
              onCancel={handleCancel}
              onOk={handleSubmit}
              okButtonProps={{ disabled: !dirty || !isValid }}
            >
              <div>
                <SelectInput
                placeholder="Select Rejection Reason"
                name="rejectedReason"
                options={rejectionReasons}
                />
              </div>
            </CustomModal>
          );
        }}
      </Formik>
      {isNewRequest && (
        <div className="drawer-footer loan-filters__footer">
          <Button
            className="drawer-footer__cancel"
            onClick={() => handleReject()}
            type="link"
          >
            Reject
          </Button>
          <Button
            className="drawer-footer__submit"
            htmlType="submit"
            onClick={() => handleApprove()}
          >
            Approve Request
          </Button>
        </div>
      )}
    </>
  );
};

export default RegularisationDetails;
