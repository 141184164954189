import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import useQueryParams from '../../../shared/hooks/useQueryParams';
import { Loan, LoanParams } from '../../../models/loan.model';
import { TabsProps } from '../../../shared/types/Tabs.type';
import ApprovedLoans from '../ApprovedLoans';
import DisbursementInProgress from '../DisbursementLoans';
import { LoanService } from '../../../services/Loan/loan.service';
import { Tabs } from 'antd';

type Props = {}

const DisbursementWrapper = (props: Props) => {

const location = useLocation();
const { updateParams } = useQueryParams({params: new LoanParams()});
const [activeTab, setActiveTab] = useState("1");

const tabs: TabsProps[] = [
    {
      label: "Approved Loans",
      key: "1",
      component: <ApprovedLoans/>,
      hash: "#approved-loans",
    },
    {
      label: "Disbursement in Progress",
      key: "2",
      component: <DisbursementInProgress/>,
      hash: "#disbursement-in-progress",
    },
  ];

useEffect(() => {

    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );

  }, [activeTab]);

  useEffect(()=> {
    if (!location.hash) {
      window.location.hash = tabs?.find((tab) => tab.key === activeTab)?.hash ?? '';
    }
  },[location.hash])

      const handleTabChange = (tabNumber: string) => {
        const hash = tabs?.find((tab, index) => tab?.key === tabNumber)?.hash;
        updateParams({ page: 1 }, hash);
        setActiveTab(tabNumber);
      }

  const tabDetails = tabs?.find((tab) => location?.hash === tab?.hash)
  const tabKey = String(tabDetails?.key)
  
  return (
    <div className='disbursement-wrapper'>
        <Tabs
        defaultActiveKey="1"
        activeKey={tabKey !== undefined ? tabKey : "1"}
        onChange={handleTabChange}
      >
        {tabs?.map(({ label, key, component }) => (
          <Tabs.TabPane tab={label} key={key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default DisbursementWrapper