import { Col, Row } from "antd";
import React, { FC } from "react";
import { Customer } from "../../../models/customer.model";
import { CustomerDetail } from "../../../models/customerDetail.model";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { LoanCustomerDetails } from "../../../models/customerDetailsNew";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { getLocation } from "../../../shared/hooks/getLocation";
import { maskNumber } from "../../../shared/utils/maskNumber";
import CustomImage from "../../../shared/components/CustomImage";
import verifiedImage from "../../../Assets/images/verified.png";
import { Loan } from "../../../models/loan.model";
import CustomDocument from "../../../shared/components/CustomDocument";

interface CustomerPersonalSummaryProps {
  customer: CustomerDetail;
  customerDetails: LoanCustomerDetails;
  loan?: Loan;
}

const CustomerPersonalSummary: FC<CustomerPersonalSummaryProps> = (props) => {
  const { customer, customerDetails, loan } = props;

  const customerData = [
    { label: "Name", value: customerDetails?.getName() },
    { label: "DOB", value: customerDetails?.getDOB() },
    { label: "Age", value: customerDetails?.getAge() },
    {
      label: "Gender",
      value: (
        <span className="text-capitalize">
          {fillEmptyData(customer?.gender)}
        </span>
      ),
    },
    { label: "Address", value: customerDetails?.address?.getAddress() },
    {
      label: "Location",
      value: customerDetails?.address?.latitude ? (
        <a
          target="_blank"
          href={getLocation(
            customerDetails?.address?.latitude ?? "",
            customerDetails?.address?.longitude ?? ""
          )}
          className="mr-5"
        >{`${customerDetails?.address?.latitude} ${customerDetails?.address?.longitude}`}</a>
      ) : (
        "NA"
      ),
    },
    {
      label: "Aadhaar Number",
      value: (
        <div className="family-details__verify">
          <span className="mr-3">
            {fillEmptyData(
              maskNumber(
                customer?.aadhaarNumber,
                !!loan?.verification?.aadhaarNumber
              )
            )}
          </span>
          {!!loan?.verification?.aadhaarNumber && (
            <CustomImage
              src={verifiedImage}
              width={19}
              height={19}
              isPopup={false}
            />
          )}
        </div>
      ),
    },
    {
      label: "PAN Number",
      value: (
        <div className="family-details__verify">
          <span className="mr-3">{fillEmptyData(customer?.panNumber)}</span>
          {!!loan?.verification?.panNumber && (
            <CustomImage
              src={verifiedImage}
              width={19}
              height={19}
              isPopup={false}
            />
          )}
        </div>
      ),
    },
    {
      label: "Email",
      value: (
        <div className="family-details__verify">
          <span className="mr-3">{fillEmptyData(customer?.email)}</span>
          {customer?.isEmailVerified && (
            <CustomImage
              src={verifiedImage}
              width={19}
              height={19}
              isPopup={false}
            />
          )}
        </div>
      ),
    },
    {
      label: "Phone",
      value: (
        <div className="family-details__verify">
          <span className="mr-3">
            {fillEmptyData(customerDetails?.phoneNumber)}
          </span>
          {customerDetails?.isMobileVerified && (
            <CustomImage
              src={verifiedImage}
              width={19}
              height={19}
              isPopup={false}
            />
          )}
        </div>
      ),
    },
    {
      label: "Education Qualification",
      value: removeUnderscore(customerDetails?.educationalQualification ?? ""),
    },
    { label: "Occupation", value: fillEmptyData(customerDetails?.occupation) },
  ];
  return (
    <div className="customer-personal-details block">
      <div className="block__heading mb-5">Customer Details</div>
      <div className="float-right">
        <CustomDocument
          width={100}
          height={100}
          src={customerDetails?.profilePicUrl}
        />
      </div>

      <Row gutter={[0, 20]}>
        {customerData?.map((data) => (
          <>
            <Col span={6} className="block__label">
              {data?.label}
            </Col>
            <Col span={18} className="block__value">
              {data?.value}
            </Col>
          </>
        ))}
      </Row>
    </div>
  );
};

export default CustomerPersonalSummary;
