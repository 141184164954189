import { Branch, BranchParams } from "../../models/branch.model";
import { deserialize, serialize } from "serializr";

import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationMeta } from "../../models/pagination.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { generatePath } from "react-router-dom";
import { useState } from "react";

export const BranchesService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const getBranches = async (params?: BranchParams) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(BranchParams, params) : {};
      const { data } = await axiosInstance.get(ApiRoutes.BRANCHES, {
        params: queryParams,
      });
      const deserializedBranch = deserialize(Branch, data?.branches as any[]);
      const deserializedBranchMeta = deserialize(PaginationMeta, data?.meta);
      return { branches: deserializedBranch, meta: deserializedBranchMeta };
    } catch (ex) {
      Notification({
        message: "Unable to Get Branch details",
        description: "Unable to Get Branch details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const addBranch = async (branch: Branch) => {
    setLoading(true);
    try {
      console.log({ branch });
      const serializedBranch = serialize(Branch, branch);
      const { data } = await axiosInstance.post(ApiRoutes.BRANCHES, {
        branch: serializedBranch,
      });
      const newBranch = deserialize(Branch, data.branch);
      Notification({
        message: "Branch Added",
        description: "Branch Added Successfully",
        type: NotificationTypes.SUCCESS,
      });
      return newBranch;
    } catch (ex) {
      Notification({
        message: "Unable to Add Branch",
        description: (ex as Error)?.message ?? "Unable to Add Branch",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateBranch = async (branch: Branch) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.BRANCH, {
        id: branch?.id ? String(branch?.id) : "",
      });
      const serializedBranch = serialize(Branch, branch);
      const { data } = await axiosInstance.put(endpoint, {
        branch: serializedBranch,
      });
      const updatedBranch = deserialize(Branch, data.branch);
      Notification({
        message: "Branch Updated",
        description: "Branch Updated Successfully",
        type: NotificationTypes.SUCCESS,
      });

      return updatedBranch;
    } catch (ex) {
      Notification({
        message: "Unable to Update Branch",
        description:
          (ex as Error)?.message ?? "Unable to Update Branch with new data",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getBranch = async (branchId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.BRANCH, {
        id: branchId,
      });
      const { data } = await axiosInstance.get(endpoint);

      const branch = deserialize(Branch, data.branch);

      return branch;
    } catch (ex) {
      Notification({
        message: "Unable to get Branch details",
        description: "Unable to get Branch details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getBranchTarget = async (params: Object, branchId: number) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.BRANCH_TARGET, {
        id: branchId ? String(branchId) : "",
      });

      const { data } = await axiosInstance.get(endpoint, { params });
      const updatedBranch = deserialize(Branch, data["target"]);

      return updatedBranch;
    } catch (ex) {
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateBranchTarget = async (branch: Branch, branchId: number) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.BRANCH_TARGET, {
        id: branchId ? String(branchId) : "",
      });
      const serializedBranch = serialize(Branch, branch);
      const { data } = await axiosInstance.put(endpoint, {
        target: serializedBranch,
      });
      const updatedBranch = deserialize(Branch, data["target"]);
      Notification({
        message: "Branch Target Updated",
        type: NotificationTypes.SUCCESS,
      });

      return updatedBranch;
    } catch (ex) {
      Notification({
        message: "Unable to Update Target Branch",
        description:
          (ex as Error)?.message ??
          "Unable to Update Target Branch with new data",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getBranches,
    addBranch,
    updateBranch,
    getBranch,
    loading,
    error,
    updateBranchTarget,
    getBranchTarget,
  };
};
