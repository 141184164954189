import { Col, Row, Space } from "antd";
import React from "react";
import accountSummary from "../../../../../Assets/images/Group 53380.svg";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { CrifReport } from "../../../../../models/creditReport.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { AccountsDetails } from "../../../../../models/accountsDetails.model";
import { convertStringForClassName } from "../../../../../shared/utils/creditReportUtil";

interface AccountSummaryProps {
  data?: CrifReport;
}

const AccountSummary = (props: AccountSummaryProps) => {
  const { data } = props;

  const groupedAccounts = data?.creditAccounts?.accountsDetails?.reduce((acc, account) => {
    const accountType = account.accountType || "Unknown Account Type";
    acc[accountType] = acc[accountType] || [];
    acc[accountType].push(account);
    return acc;
  }, {} as Record<string, AccountsDetails[]>);


  const createTitleCardData = (account: AccountsDetails): ListMappingKeys[] => [
    { label: "Last Updated", value: fillEmptyData(account.reportedOn), key: "1", offset: 14 },
    { label: "Status", value: fillEmptyData(account.accountStatus), key: "2", offset: 4  },
  ];

  return (
    <div>
      <Row className="card crif-card" align="middle">
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={accountSummary} alt="" />
          </Col>
          <Col>Account Summary</Col>
        </Space>
        <Col offset={19} className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">
              {data?.creditAccounts?.totalCreditAccounts || 0}
            </span>
          </div>
          <span className="text__green text-right">{fillEmptyData(data?.creditAccounts?.creditStatus)}</span>
        </Col>
      </Row>
      {groupedAccounts &&
        Object?.entries(groupedAccounts).map(([accountType, accounts]) => (
          <div className="card crif-card mt-5 account-summary-card" key={accountType}>
            <p className="text__label-green font-weight__600">
              {accountType} - {accounts.length}
            </p>
            {accounts.map((account, accIndex) => (
              <Row key={`${accountType}-${accIndex}`} className={accIndex > 0 ? "keyfactor-row mt-5" : "keyfactor-row"}>
    
                  <Col span={1} className="key-img-container">
                    <img src={accountSummary} alt="Account Summary Logo" />
                  </Col>
                  <Col className="flex-column key-factor-col">
                    <span className="text__value">{fillEmptyData(account.lender)}</span>
                    <span className="text__label">{fillEmptyData(account.accountType)}</span>
                  </Col>

                {createTitleCardData(account).map((data) => (
                  
                  <Col offset={3} key={data.key} className="flex-column" >
                    <span className={`text__value status--${convertStringForClassName(data?.value)}`}>{data.value}</span>
                    <span className="text__label">{data.label}</span>
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        ))}
    </div>
  );
};

export default AccountSummary;
