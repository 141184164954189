import React, { useEffect, useState } from "react";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import {
  Loan,
  Loan as LoanModel,
  LoanParams,
} from "../../../models/loan.model";
import { generatePath, useNavigate } from "react-router-dom";
import useSorting from "../../../shared/hooks/useSorting";
import { TableConfig } from "../../../enums/tableConfig.type";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { Filters } from "../../../models/filters.model";
import { LoanService } from "../../../services/Loan/loan.service";
import { LoanStatus } from "../../../enums/loanStatus.type";
import Table from "../../../shared/components/Table";
import { Button, Checkbox, Drawer, Modal, Row, TableColumnsType, TableProps } from "antd";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { SorterResult } from "antd/lib/table/interface";
import FilterButtons from "../../../shared/components/FilterButtons";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import useDrawer from "../../../shared/hooks/useDrawer";
import LoanFilters from "../IndividualDisburesement/DisburesementFilters";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import DownloadIcon from "../../../Assets/images/downloadIcon.png";
import ChangeStatus from "../IndividualDisburesement/ChangeStatus";
import { modLoansTypes } from "../../../enums/modLoans.type";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { ColumnProps } from "antd/lib/table";
import { getFullName } from "../../../shared/utils/getFullName";
import { MODStatus } from "../../../enums/modStatusTags.type";
import { updateSelectedValue } from "../../../shared/utils/modFilter";
import MODUploadForm from "../../MODDocuments/MODUploadForm";
import { renderModStatus } from "../../../shared/utils/renderModStatus";

type Props = {};

const sortBy = {
  code: "code",
  centerName: "center_name",
  name: "customer_name",
  createdByFirstName: "created_by.firstname",
  loanTypeName: "loan_type_name",
  status: "status",
  organisations: "organisations",
  organizationName: "organisation_name",
  currentStatusTag: "current_status_tag"
};

const DisbursementInProgress = (props: Props) => {
  const {
    params: disbursedParams,
    updateParams,
    getParams,
    handleSearch,
  } = useQueryParams({
    params: new LoanParams(),
  });

  const columns: ColumnProps<Loan>[] = [
    {
      title: "Loan ID",
      dataIndex: "code",
      render: (code: string) => code?.toUpperCase(),
      sorter: true
    },
    {
      title: "Partner ID",
      dataIndex: "laf",
      render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
    },
    {
      title: "BC Status Tag",
      dataIndex: "currentStatusTag",
      render: (_, record) => {
        if(!record?.currentStatusTag) return " - "
        const className =
          "active-status--" + record.currentStatusTag?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record.getBCTagLabel() ?? "")}
          </span>
        );
      },
      sorter: true
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      render: (_, record) => record?.customer?.getName(),
      sorter: true
    },
    {
      title: "Security",
      dataIndex: "securedType",
      render: (security: string) => renderModStatus(security),
    },
    {
      title: "MOD Status",
      dataIndex: "modStatus",
      render: (_, record) => {
        if(!record?.modStatus) return " - "
        const className =
          "active-status--" + record.modStatus?.split("_")?.join("-");
          const isUpload = record.modStatus === MODStatus.UPLOAD_MOD || (record.modStatus === MODStatus.PENDING_ACKNOWLEDGEMENT && !record.modDocumentUrl)
          const isEmLoan = record.securedType === modLoansTypes.EQUAITABLE_MORTAGE
        return (
          <span
          className={`text-success text-capitalize active-status ${className} ${((isUpload && !isEmLoan) && "text-underline")}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (isUpload && !isEmLoan) {
                setUploadModal(true)
                setModLoan(record)
              }
            }}
          >
            {removeUnderscore(record.getModStatusLabel() ?? "")}
          </span>
        );
      },
    },
    {
      title: "Document ID",
      dataIndex: "modDocumentId",
      render: (_, record: Loan) => record?.modDocumentId
    },
    {
      title: "Center",
      dataIndex: "centerName",
      sorter: true,
    },

    {
      title: "CRO",
      dataIndex: "createdByFirstName",
      sorter: true,
      render: (_, record) => getFullName(record?.createdBy),
    },
    {
      title: "Loan Type",
      dataIndex: "loanTypeName",
      sorter: true,
    },
    {
      title: "Batch Id",
      dataIndex: "batchId",
    },
    {
      title: "Branch Name",
      dataIndex: "branch",

      render: (_: string, record: Loan) => record?.branch?.name,
    },

    {
      title: "Total Amount",
      dataIndex: "requestedLoanAmt",
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (_, record) => {
        const className = "active-status--" + record.status?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record.getLoanStatusLabel() ?? "")}
          </span>
        );
      },
    },
    {
      title: "Preferred channel ",
      dataIndex: "organizationName",
      sorter: true
    },
  ];

  const navigate = useNavigate();
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.disbursement ?? []
  );
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );
  const [filters, setFilters] = useState<Filters>(new Filters());
  const { updateSortData } = useSorting<Loan>({ sortBy });
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loanTotalPage, setLoanTotalPage] = useState(1);
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(columns);
  const [isLoanGenerated, setIsLoanGenerated] = useState(false);
  const [selectedLoans, setSelectedLoans] = useState<number[]>([]);
  const [loanDetails, setLoanDetails] = useState(false)
  const [modLoan, setModLoan] = useState<Loan>()
  const [uploadModModal, setUploadModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState(disbursedParams?.securityType);

  const { getLoans, loading, generateLoanReport } =
    LoanService();

  useEffect(() => {
    setColumnConfig(TableConfig.DISBURSEMENT, filteredColumns);
  }, [filteredColumns]);

  const fetchLoans = async () => {
    const updatedParams = {
      ...disbursedParams,
      status: [LoanStatus.DISBURSEMENT_IN_PROGRESS_GET],
      searchText: disbursedParams?.searchText,
    };

    const loanDetails = await getLoans(updatedParams);
    const val = loanDetails?.loans?.filter((loan) => loan?.status === LoanStatus.DISBURSEMENT_IN_PROGRESS_GET);
    if (val) setLoans(val);
    if (loanDetails?.meta) {
      if (loanDetails.meta.filters) {
        const updatedFilters = { ...loanDetails.meta.filters };
        delete updatedFilters.statuses;
        setFilters(updatedFilters);
      }
      setLoanTotalPage(loanDetails.meta.totalCount ?? 1);
    }
  };

  useEffect(() => {
    fetchLoans();
  }, [disbursedParams, disbursedParams.searchText]);

  const handleSelectedLoans = (loans: number[]) => setSelectedLoans(loans);

  const {
    visible: disbursedSettingsVisible,
    toggleVisibility: toggleDisbursedSettingsVisible,
  } = useDrawer({});

  const {
    visible: disbursedFiltersVisible,
    toggleVisibility: toggleDisbursedFiltersVisible,
  } = useDrawer({});

  const {
    visible: isChangeStatusVisible,
    toggleVisibility: toggleChangeStatusVisible,
  } = useDrawer({});


  const rowSelection = {
    selectedRowKeys: selectedLoans,
    onChange: (selectedRowKeys: React.Key[], loans: Loan[]) => {
      const paymentIntentIds = loans.map((loans) => loans?.id ?? 0);
      handleSelectedLoans(paymentIntentIds);

      if(selectedRowKeys.length === 0) {
        setLoanDetails(false)
      }

    },
  };

  const handleUpdateParams = () => updateParams(getParams());

  const handleGenerateReport = async () => {
    const report = await generateLoanReport({
      loanIds: selectedLoans,
      templateName: "export_template",
    });
    report && setIsLoanGenerated(true);
  };

  const handleRowChange = (record: Loan) => ({
    onClick: () =>
      record?.id &&
      navigate(
        generatePath(AppRoutes.DISBURSEMENT_DETAILS, {
          loanId: String(record?.id),
        })
      ),
  });

  const handleChange: TableProps<LoanModel>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<Loan>
    );
    updateParams({ ...disbursedParams, page: current, sortBy, sortDirection });
  };

  const changeStatusClose = () => {
    toggleChangeStatusVisible(false);
    handleSelectedLoans([]);
    fetchLoans()
  }
  const modFilterChange = (value: string) => {
    updateSelectedValue(value, setSelectedValue, updateParams);
  };
  
  return (
    <div className="approved-loans">
      {!loading && filters && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
         <div className="mb-5">
            <Checkbox
              checked={selectedValue === modLoansTypes.ALL}
              onChange={() => modFilterChange(modLoansTypes.ALL)}
            >
              All Loans
            </Checkbox>
            <Checkbox
              checked={disbursedParams?.securityType === modLoansTypes.SECURED}
              onChange={() => modFilterChange(modLoansTypes.SECURED)}
            >
              Secured Loans
            </Checkbox>
            <Checkbox
              checked={disbursedParams?.securityType === modLoansTypes.UNSECURED}
              onChange={() => modFilterChange(modLoansTypes.UNSECURED)}
            >
              Unsecured Loans
            </Checkbox>
          </div>
      <Table
        className={`approved-loans-table ${loanDetails ? 'approved-loans-table-with-details' : ''}`}
        rowKey="id"
        scroll={{ x: true }}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        {...(userAccess?.writeAccess && {
          rowSelection: {
            preserveSelectedRowKeys: true,
            type: "checkbox",
            ...rowSelection,
          },
        })}
        dataSource={loans}
        loading={loading}
        onRow={handleRowChange}
        showSorterTooltip={false}
        onChange={handleChange}
        pagination={{
          current: disbursedParams?.page,
          total: loanTotalPage,
          hideOnSinglePage: true,
        }}
      />
       
        <div className={`approved-extra-content ${loanDetails ? 'approved-top' : ''}`}>
        <div className={`selected-loan-details ${loanDetails ? 'extra-margin' : ''}`}>
        <Row gutter={[20, 0]}>
              {userAccess?.writeAccess && (
                <>
                  <Button
                    disabled={!selectedLoans.length}
                    className="loan-status-btn ml-2"
                    onClick={() => toggleChangeStatusVisible(true)}
                  >
                    Change Status
                  </Button>
                  <Button
                    disabled={!selectedLoans.length}
                    className="loan-export-btn ml-2"
                    onClick={handleGenerateReport}
                  >
                    <img className="download-icon mr-5" src={DownloadIcon} />
                    EXPORT DATA
                  </Button>
                </>
              )}
            </Row>
            
        </div>
     
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Loan",
            value: disbursedParams.searchText,
          }}
          filterClick={toggleDisbursedFiltersVisible}
          settingsClick={toggleDisbursedSettingsVisible}
        />
      </div>
      <Drawer
        placement="right"
        visible={disbursedSettingsVisible}
        destroyOnClose
        onClose={() => toggleDisbursedSettingsVisible(false)}
        title="Column Options"
        width={500}
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleDisbursedSettingsVisible(false)}
          setDefaultColumns={setDefaultColumns}
        />
      </Drawer>
      <Modal
        visible={isChangeStatusVisible}
        onCancel={() => toggleChangeStatusVisible(false)}
        cancelButtonProps={{ className: "modal-footer__cancel" }}
        destroyOnClose
        centered
        footer={false}
      >
        <ChangeStatus
          selectedLoans={selectedLoans}
          activeTab="2"
          onClose={changeStatusClose}
        />
      </Modal>
      <Drawer
        placement="right"
        visible={disbursedFiltersVisible}
        destroyOnClose
        onClose={() => toggleDisbursedFiltersVisible(false)}
        title="Filters"
        width={"70vw"}
      >
        <LoanFilters
          onFilter={updateParams}
          onClose={() => toggleDisbursedFiltersVisible(false)}
        />
      </Drawer>
      <MODUploadForm refreshData={fetchLoans} loan={modLoan} visible={uploadModModal} onClose={()=> setUploadModal(false)} securityDocument={true} />
    </div>
  );
};

export default DisbursementInProgress;
