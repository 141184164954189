import React, { useEffect, useState } from "react";
import "../loanClaims.scss";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { LoanClaimsModel, LoanClaimsParams, ProcessGroupClaim } from "../../../models/loanClaims.model";
import Table from "../../../shared/components/Table";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import useSorting from "../../../shared/hooks/useSorting";
import useDrawer from "../../../shared/hooks/useDrawer";
import { Button, Col, Drawer, Row, TableColumnsType, TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { TableConfig } from "../../../enums/tableConfig.type";
import { ColumnProps } from "antd/lib/table";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { DateFormat } from "../../../enums/dateFormat.type";
import {
  currencyFormatter,
  dateTimeFormatter,
} from "../../../shared/utils/formatter";
import CustomModal from "../../../shared/components/CustomModal";
import DatePickerField from "../../../shared/components/DatePickerField";
import { Form, Formik } from "formik";
import { processClaimValidation } from "../../Loans/LoanIndividualDetails/LoanClaims/processClaimValidation";
import { ClaimsService } from "../../../services/Claims/claims.service";
import { toTitleCase } from "../../../shared/utils/titleCaseConverter";
import { LoansClaimsStatus } from "../../../enums/loanClaimsStatuses.type";
import SelectInput from "../../../shared/components/SelectInput";
import insurerNameOptions from "../../../shared/Constant/insurerNameOptions";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";

const sortBy = {
  loanCode: "loan_code",
  role: "role",
  totalInsured: "total_insured",
  deathDate: "death_date",
};

const InitiatedClaims = () => {
  const {
    params: initiatedParams,
    handleSearch,
    updateParams,
  } = useQueryParams<LoanClaimsParams>({
    params: new LoanClaimsParams(),
  });

  const initatedColumns: ColumnProps<LoanClaimsModel>[] = [
    {
      title: "Loan ID",
      dataIndex: "loanCode",
      render: (loanCode: string) => fillEmptyData(loanCode?.toUpperCase()),
      sorter: true,
    },
    {
      title: "Name of Expired",
      dataIndex: "nameOfExpired",
      render: (name: string) => fillEmptyData(name),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role: string) => fillEmptyData(toTitleCase(removeUnderscore(role))),
      sorter: true,
    },
    {
      title: "Death Date",
      dataIndex: "deathDate",
      render: (_, record: LoanClaimsModel) =>
        dateTimeFormatter(record.deathDate, DateFormat.DATE),
      sorter: true,
    },
    {
      title: "Branch",
      dataIndex: "branch.name",
      render: (text, record) => {
        return fillEmptyData(record?.branch);
      },
    },
    {
      title: "CRO",
      dataIndex: "cro",
      render: (text: string, record: LoanClaimsModel) => {
        return fillEmptyData(toTitleCase(record?.croName ?? ""))
      },
    },
    {
      title: "Total Insured",
      dataIndex: "totalInsured",
      render: (_, record: LoanClaimsModel) => {
        return currencyFormatter({
          amount: record.totalInsured,
          hasCurrencySign: true,
        });
      },
      sorter: true,
    },
    {
      title: "Outstanding",
      dataIndex: "outstanding",
      render: (_, record: LoanClaimsModel) => {
        return currencyFormatter({
          amount: record.outstanding,
          hasCurrencySign: true,
        });
      },
    },
    {
      title: "To Customer",
      dataIndex: "toCustomer",
      render: (_, record: LoanClaimsModel) => {
        return currencyFormatter({amount: record?.toCustomer, hasCurrencySign: true})
      },
    },
    {
      title: "To Velicham",
      dataIndex: "toVelicham",
      render:(_, record: LoanClaimsModel) => {
        return currencyFormatter({amount: record?.toVelicham, hasCurrencySign: true})
      }
    },
    {
      title: "Nominee Name",
      dataIndex: "nomineeName",
      render: (_, record: LoanClaimsModel) => fillEmptyData(toTitleCase(removeUnderscore(record.nomineeName))),
    },
    {
      title: "Death Certificate",
      dataIndex: "deathCertificate",
      render: (_, record) => {
        const className = "active-status--" + record.deathCertificateUploaded
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {record?.deathCertificateUploaded ? "Uploaded" : "Not Uploaded"}
          </span>
        );
      },
    }
  ];

  const [totalClaims, setTotalClaims] = useState(1);
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [selectedClaims, setSelectedClaims] = useState<number[]>([]);
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.loanClaims ?? []
  );
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<LoanClaimsModel>>(initatedColumns);
  const [claims, setClaims] = useState<LoanClaimsModel[]>([]);
  const { updateSortData } = useSorting<LoanClaimsModel>({ sortBy });
  useEffect(() => {
    setColumnConfig(TableConfig.LOAN_CLAIMS, filteredColumns);
  }, [filteredColumns]);

  const {
    visible: initiatedSettingsVisible,
    toggleVisibility: toggleInitiatedSettingsVisibility,
  } = useDrawer({});

  const { visible: isModalVisible, toggleVisibility: toggleModalVisible } =
    useDrawer({});

  const handleChange: TableProps<LoanClaimsModel>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<LoanClaimsModel>
    );
    updateParams({
      ...initiatedParams,
      page: current, sortBy, sortDirection
    });
  };

  const { getClaims, processGroupClaims, loading } = ClaimsService()
  const navigate = useNavigate();

  const handleSubmit = async (values: ProcessGroupClaim) => {
    await processGroupClaims({
      claimIds: selectedClaims,
      claimSentDate: values.claimSentDate,
      insurerName: values.insurerName,
    })
    toggleModalVisible(false);
    setTimeout(() => {
      window.location.reload();
    }, 500); 
  };

  useEffect(() => {
    fetchClaims();
  }, [initiatedParams, initiatedParams.searchText]);

  const fetchClaims = async () => {
    const updatedParams = {
      ...initiatedParams,
      searchText: initiatedParams.searchText,
      status: LoansClaimsStatus.INITIATED
    };
    const claimsData = await getClaims(updatedParams);
    if (claimsData?.claims) setClaims(claimsData?.claims);
    if (claimsData?.meta) {
      setTotalClaims(claimsData?.meta?.totalCount ?? 1);
    }
  };

  const handleSelectedClaims = (claims: number[]) => setSelectedClaims(claims);

  const rowSelection = {
    selectedRowKeys: selectedClaims,
    onChange: ( selectedRowKeys: React.Key[], claims: LoanClaimsModel[],) => {
      const claimIds = claims.map((claims) => claims?.id ?? 0);
      handleSelectedClaims(claimIds);
    },
  };

  const changeStatusClose = () => {
    toggleModalVisible(false);
    handleSelectedClaims([]);
    fetchClaims();
  };

  const handleRowChange = (record: LoanClaimsModel) => ({
    onClick: () => {
      navigate(
        generatePath(AppRoutes.INDIVIDUAL_LOAN_CLAIM, {
          loanId: String(record?.loanId),
        })
      );
    },
  })

  return (
    <div className="initiated-claims">
      <div className="initiated-claims-extra-content">
        <Button
          disabled={!selectedClaims.length}
          className="loan-status-btn mr-5"
          onClick={() => toggleModalVisible(true)}
        >
          Process Claim
        </Button>
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Document",
            value: initiatedParams.searchText,
          }}
          settingsClick={toggleInitiatedSettingsVisibility}
        />
      </div>
      <Table
        onChange={handleChange}
        scroll={{ x: true }}
        loading={loading}
        className="initiated-claims-table"
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        {...({
          rowSelection: {
            preserveSelectedRowKeys: true,
            type: "checkbox",
            ...rowSelection,
          },
        })}
        dataSource={claims}
        rowKey={(record: LoanClaimsModel) => record?.id as number}
        onRow={handleRowChange}
        pagination={{
          current: initiatedParams?.page,
          hideOnSinglePage: true,
          total: totalClaims,
        }}
      />
      
      <Drawer
        placement="right"
        visible={initiatedSettingsVisible}
        destroyOnClose
        onClose={() => toggleInitiatedSettingsVisibility(false)}
        title="Column Options"
        width={500}
        className="initiated-claims__settings-drawer"
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleInitiatedSettingsVisibility(false)}
          setDefaultColumns={setDefaultColumns}
        />
      </Drawer>
      <CustomModal title="" visible={isModalVisible} isOkButton={false} isCancelButton={false} className="process-claim-modal" onClose={changeStatusClose}>
        <Formik initialValues={new ProcessGroupClaim()} onSubmit={handleSubmit} validationSchema={processClaimValidation}>
          {({ setFieldValue, isValid, dirty, values, resetForm }) => {
            const handleModalCancel = () => {
              toggleModalVisible(false);
              resetForm();
              navigate(0)
            };
            return (
              <Form>
                <Row>
                  <Col>
                  <h1>Process Claim</h1>
                  </Col>
                  <Col>                
                    <p>Select the date on which you sent selected loan to claim</p>
                  </Col>
                  <Col span={24}>
                  <DatePickerField
                    name="claimSentDate"
                    setFieldValue={setFieldValue}
                    type="date"
                  />
                  </Col>
                  <Col span={24}>
                  <SelectInput name="insurerName" options={insurerNameOptions} placeholder="Select an Insurer"/>
                  </Col>
                  </Row>
                  <div className="modal__footer text-right">
                    <Button
                      htmlType="reset"
                      onClick={handleModalCancel}
                      className="modal-footer__cancel"
                      type="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal-footer__submit process-claims-submit-button"
                      htmlType="submit"
                      disabled={!dirty || !isValid}
                    >
                      Confirm
                    </Button>
                  </div>
              </Form>
            );
          }}
        </Formik>
      </CustomModal>
    </div>
  );
};

export default InitiatedClaims;
