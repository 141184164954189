import React, { useEffect, useState } from "react";
import { TabsProps } from "../../../shared/types/Tabs.type";
import { useLocation } from "react-router-dom";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { LoanParams } from "../../../models/loan.model";
import { Tabs } from "antd";
import InitiatedClaims from "../Initiated";
import ProcessingClaims from "../Processing";
import ClaimedClaims from "../Claimed";

const LoanClaimsWrapper = () => {
  const location = useLocation();

  const { updateParams } = useQueryParams({ params: new LoanParams() });
  const [activeTab, setActiveTab] = useState("1");
  const tabs: TabsProps[] = [
    {
      label: "Initiated",
      key: "1",
      component: <InitiatedClaims />,
      hash: "#initated-claims",
    },
    {
      label: "Processing",
      key: "2",
      component: <ProcessingClaims />,
      hash: "#processing-claims",
    },
    {
      label: "Claimed",
      key: "3",
      component: <ClaimedClaims />,
      hash: "#claimed-claims",
    },
  ];

  useEffect(() => {
    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );
  }, [activeTab]);

  useEffect(() => {
    if (!location.hash) {
      window.location.hash =
        tabs?.find((tab) => tab.key === activeTab)?.hash ?? "";
    }
  }, [location.hash]);

  const handleTabChange = (tabNumber: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === tabNumber)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(tabNumber);
  };

  const tabDetails = tabs?.find((tab) => location?.hash === tab?.hash);
  const tabKey = String(tabDetails?.key);

  return (
    <div className="loan-claims-wrapper">
      <Tabs
        defaultActiveKey="1"
        activeKey={tabKey !== undefined ? tabKey : "1"}
        onChange={handleTabChange}
      >
        {tabs?.map(({ label, key, component }) => (
          <Tabs.TabPane tab={label} key={key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default LoanClaimsWrapper;
