import { Filters } from "../../models/filters.model";
import { Location } from "history";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import { Meta } from "../../models/meta.modal";

interface FilterKeys {
  roleIds?: Meta[];
  createdById?: Meta[];
  customerId?: Meta[];
  branchesId?: Meta[];
  organisationIds?: Meta[];
  organisations?: Meta[];
  centersId?: Meta[];
  centerBranchId?: Meta[];
  branchId?: Meta[];
  branchIds?: Meta[];
  centersIds?: Meta[];
  centerId?: Meta[];
  branchesIds?: Meta[];
  loanTypeIds?: Meta[];
  loanTypeId?: Meta[];
  status?: Meta[];
  regionId?: Meta[];
  responsibleEmployee?: Meta[];
  isActive?: Meta[];
  securityTypes?: Meta[];
  hypothecatorId?: Meta[];
  disbursementDate?: Meta[];
  maximumLoanAmount?: Meta[];
  minimumLoanAmount?: Meta[];
  totalAmount?: Meta[];
  tenure?: Meta[];
  isHypothecator?: Meta[];
  stateId?: Meta[];
}

export function replaceFilterKeys(
  obj: Filters,
  location: Location
): FilterKeys {
  const pathName = location?.pathname;

  const {
    branches,
    centers,
    roles,
    organisations,
    statuses,
    createdBy,
    loanTypes,
    customerId,
    regions,
    responsibleEmployee,
    isActive,
    maximumLoanAmount,
    minimumLoanAmount,
    totalAmount,
    tenure,
    hypothecators,
    securityTypes,
    disbursementDate,
    isHypothecator,
    states
  } = obj;

  let filters: FilterKeys = {
    roleIds: roles,
    createdById: createdBy,
    customerId,
  };

  switch (pathName) {
    case AppRoutes.USERS:
      filters = {
        ...filters,
        branchesId: branches,
        organisationIds: organisations,
        centersId: centers,
      };
      break;

    case AppRoutes.CUSTOMERS:
      filters = { ...filters, centerBranchId: branches, organisations };
      break;

    case AppRoutes.PIPELINE:
    case AppRoutes.LOANS:
    case AppRoutes.DISBURSEMENT:
    case AppRoutes.CT_VERIFICATION:
      filters = {
        ...filters,
        centerId: centers,
        organisations,
        branchId: branches,
        loanTypeId: loanTypes,
        status: statuses,
        maximumLoanAmount: maximumLoanAmount,
        minimumLoanAmount: minimumLoanAmount,
        hypothecatorId: hypothecators,
        totalAmount: totalAmount,
        tenure: tenure,
        securityTypes: securityTypes,
        disbursementDate: disbursementDate,
        isHypothecator: isHypothecator,
        stateId: states,
      };
      break;

    case AppRoutes.REPAYMENTS:
      filters = {
        ...filters,
        branchIds: branches,
        loanTypeIds: loanTypes,
        status: statuses,
      };
      break;
    case AppRoutes.CONFIGURATIONS:
        filters = {
          regionId: regions,
          responsibleEmployee: responsibleEmployee,
          isActive: isActive,
          branchId: branches,
        }
      break
    
    case AppRoutes.MOD_DOCUMENTS:
      filters = {
        ...filters,
        branchId: branches,
        securityTypes: securityTypes
      }

      break;
      
    default:
      break;
  }

  return filters;
}