import { alias, list, object, primitive, serializable } from "serializr";
import { QueryParams } from "./query.params.model";
import { Branch } from "./branch.model";
import { CreatedBy } from "./createdBy.model";
import { Document } from "./document.model";

export class LoanClaimsParams extends QueryParams{
    @serializable(alias('status', primitive()))
    status?: string;
}

export class LoanClaimsModel  {

    @serializable(alias('loan_code', primitive()))
    loanCode?: string;

    @serializable(alias('document_type', primitive()))
    documentType?: string;

    @serializable(alias('death_certificate_uploaded', primitive()))
    deathCertificateUploaded?: boolean

    @serializable(alias('claim_amount', primitive()))
    claimAmount?: number

    @serializable(alias('cro_updated_on', primitive()))
    croUpdatedOn?: string

    @serializable(primitive()) 
    id?: number;

    @serializable(alias('loan_id', primitive()))
    loanId?: number;

    @serializable(alias('name_of_expired', primitive()))
    nameOfExpired?: string;

    @serializable(alias('claim_document_id', primitive()))
    claimDocumentId?: number;

    @serializable(alias('role_of_demised', primitive()))
    role?: string;

    @serializable(alias('date_of_death', primitive()))
    deathDate?: string;

    @serializable(alias('branch', primitive()))
    branch?: string;

    @serializable(alias('claim_ids', primitive()))
    claimIds?: number

    @serializable(alias('claim_date', primitive()))
    claimDate?: string;

    @serializable(alias("cro", primitive()))
    croName?: string;

    @serializable(alias('created_by_first_name', primitive()))
    createdByFirstName?: string

    @serializable(alias('to_be_delivered', primitive()))
    toBeDelivered?: string

    @serializable(alias("created_by", object(CreatedBy)))
    createdBy = new CreatedBy();

    @serializable(alias("total_insured", primitive()))
    totalInsured?: number

    @serializable(alias('claim_status', primitive()))
    claimStatus?: string

    @serializable(alias('customer_status', primitive()))
    customerStatus?: boolean

    @serializable(alias("documents", list(object(Document))))
    documents: Document[] = [];

    @serializable(alias('outstanding_amount', primitive()))
    outstanding?: number

    @serializable(alias('insurer_name', primitive())) 
    insurer?: string

    @serializable(alias('nominee_name', primitive()))
    nomineeName?: string

    @serializable(alias('amount_to_customer', primitive()))
    toCustomer?: number

    @serializable(alias('amount_to_velicham', primitive()))
    toVelicham?: number

    @serializable(alias('claim_sent_date', primitive()))
    claimSentOn?: string

    @serializable(alias('claim_received_date', primitive()))
    claimedDate?: string

    @serializable(alias('days_to_claim', primitive()))
    daysToClaim?: string

    @serializable(alias('status', primitive()))
    status?: string

    @serializable(alias('claim_received_by_customer', primitive()))
    claimReceivedByCustomer?: boolean

    @serializable(alias('cro_updated_after', primitive()))
    croUpdatedAfter?: string

    @serializable(alias('no_of_dues_pending', primitive()))
    numberOfDuesPending?: number

    @serializable(alias('wallet_amount', primitive()))
    walletAmount?: number

}

export class ProcessGroupClaim {
    @serializable(alias('claim_ids', list(primitive())))
    claimIds?: number[] = [];

    @serializable(alias('claim_sent_date', primitive()))
    claimSentDate?: string;

    @serializable(alias('insurer_name', primitive()))
    insurerName?: string
}

export class ProcessIndividualClaim {
    @serializable(alias('claim_ids', primitive()))
    claimId?: number;

    @serializable(alias('claim_sent_date', primitive()))
    claimSentDate?: string;

    @serializable(alias('insurer_name', primitive()))
    insurerName?: string
}

export class ProcessMarkedClaim {
    @serializable(alias('claim_ids', primitive()))
    claimId?: number;

    @serializable(alias('claim_received_date', primitive()))
    claimReceivedDate?: string;

    @serializable(alias('claim_received_by_customer', primitive()))
    claimReceivedByCustomer?: boolean

    @serializable(alias('claim_amount', primitive()))
    claimAmount?: number
}

export class ClaimDocumentUpload {
    @serializable(alias('document_id', primitive()))
    documentId?: number

    @serializable(alias('document_name', primitive()))
    documentName?: string

    @serializable(alias('claim_id', primitive()))
    claimId?: number

    @serializable(alias('death_certificate_id', primitive()))
    deathCertificateId?: number

    @serializable(alias('document_type', primitive()))
    documentType?: string
}

export class DeathCertificateDocumentUpload {
    @serializable(alias('death_certificate_id', primitive()))
    deathCertificateId?: number
}